import { SingleValue } from 'react-select';
import { GoodServiceUnit } from '../../models/invoiceModel';
import { OptionModel } from '../../models/optionModel';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  value?: string;
  onChange?: (operation?: GoodServiceUnit) => void;
}

export function GoodServiceUnitSelect(props: Props) {
  const { onChange } = props;
  const { value } = props;

  return (
    <QuickfiscoSelect
      id={'good-service-unit-select'}
      label={'Tipo'}
      onChange={(e: SingleValue<OptionModel>) => {
        if (e !== null) {
          // @ts-ignore
          onChange && onChange(e.value);
        }
      }}
      placeholder='Seleziona'
      options={getOptions(value)}
      requiredField={true}
      styleType={'default'}
      value={
        getOptions(value).filter(
          (option) => option.selected === true
        )[0]
      }
    />
  );
}

function getOptions(value?: string) {
  return [
    {
      label: 'Importo fisso',
      value: GoodServiceUnit.FIXED,
      selected: value === GoodServiceUnit.FIXED,
    },
    {
      label: 'Percentuale',
      value: GoodServiceUnit.PERCENTAGE,
      selected: value === GoodServiceUnit.PERCENTAGE,
    },
  ];
}
import { useEffect, useState } from 'react';
import { FeesRegisterModel } from '../../models/feesRegisterModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  feesRegisterEdit,
  feesRegisterFindAll,
  feesRegisterSave,
  initialState,
  setFeesRegisterAmountEu,
  setFeesRegisterAmountExtraEu,
  setFeesRegisterAmountIt,
  setFeesRegisterAteco,
  setFeesRegisterDescription,
  setFeesRegisterFindByIdStatus,
  setResetFeesRegister,
} from '../../redux/slices/feesRegisterSlice';
import { formatNumberIT, parseNumber } from '../../utils/number';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { AtecoSelect } from '../atecoSelect/atecoSelect';
import './feesRegisterEdit.css';
import lang from './feesRegisterEdit.json';

interface Props {
  feesRegister: FeesRegisterModel;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function FeesRegisterEditOrSave(props: Props) {
  const { feesRegister } = props;
  const { open } = props;
  const { setOpen } = props;

  const ateco479110 =
    '47.91.10 - Commercio al dettaglio di qualsiasi tipo di prodotto effettuato via internet';
  const feesRegisterState = useAppSelector((state) => state.feesRegister);
  const userState = useAppSelector((state) => state.user.user);

  const findByIdStatus = feesRegisterState.findByIdStatus;
  const editStatus = feesRegisterState.editStatus;
  const saveStatusSlice = feesRegisterState.saveStatus;
  const editFeesRegister = feesRegisterState.feesRegisterToEdit;

  const [saveStatus, setSaveStatus] = useState(true);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (feesRegisterState.findByIdStatus === 'successfully') {
      setSaveStatus(false);
    }
  }, [feesRegisterState.findByIdStatus]);

  const applyAteco = (ateco: string) => {
    dispatch(setFeesRegisterAteco(ateco));
    return ateco;
  };

  return (
    <QuickfiscoModal
      isOpen={open}
      hide={() => {
        setOpen(false);
        dispatch(setFeesRegisterFindByIdStatus('idle'));
        dispatch(setResetFeesRegister(initialState.feesRegisterToEdit));
      }}
    >
      {findByIdStatus === 'loading' ? (
        <div className={'d-flex justify-content-center'}>
          <QuickfiscoSpinner />
        </div>
      ) : (
        <div className={'p-4 pt-0'}>
          <div className="row">
            <div className="col-auto m-auto">
              <span className="fees-register-edit-title">{lang.title}</span>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-auto m-auto">
              <span className="fees-register-edit-subtitle">
                {lang.subtitle}
              </span>
            </div>
          </div>
          <div className={'row mt-5'}>
            <div className={'col-12'}>
              <AtecoSelect
                id={'fees-register-ateco'}
                onChange={(_ateco) =>
                  _ateco && dispatch(setFeesRegisterAteco(_ateco))
                }
                value={
                  (!editFeesRegister.ateco || editFeesRegister.ateco === '') &&
                  userState.atecos.includes(ateco479110)
                    ? applyAteco(ateco479110)
                    : (!editFeesRegister.ateco ||
                        editFeesRegister.ateco === '') &&
                      !userState.atecos.includes(ateco479110)
                    ? applyAteco(userState.atecos[0])
                    : editFeesRegister.ateco
                }
                type={'own'}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-6">
              <QuickfiscoInput
                id="fees-register-description-input"
                type={'text'}
                styleType={'default'}
                label={lang.description}
                placeholder={lang.placeholderDescription}
                defaultValue={feesRegister.description}
                onChange={(e) =>
                  dispatch(setFeesRegisterDescription(e.target.value))
                }
              />
            </div>
            <div className="col-6">
              <QuickfiscoDatePicker
                id="fees-register-date-input"
                label={lang.date}
                disabled={true}
                value={new Date(feesRegister.date)}
                styleType={'default'}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-4">
              <QuickfiscoInput
                id="fees-register-amount-it-input"
                type={'text'}
                styleType={'default'}
                label={lang.itAmount}
                placeholder={lang.amountPlaceholder}
                onChange={(e) => {
                  dispatch(
                    setFeesRegisterAmountIt(parseNumber(e.target.value))
                  );
                }}
                defaultValue={formatNumberIT(feesRegister.amount_IT)}
              />
            </div>
            <div className="col-4">
              <QuickfiscoInput
                id="fees-register-amount-eu-input"
                type={'text'}
                styleType={'default'}
                label={lang.euAmount}
                placeholder={lang.amountPlaceholder}
                onChange={(e) => {
                  dispatch(
                    setFeesRegisterAmountEu(parseNumber(e.target.value))
                  );
                }}
                defaultValue={formatNumberIT(feesRegister.amount_EU)}
              />
            </div>
            <div className="col-4">
              <QuickfiscoInput
                id="fees-register-amount-extra-eu-input"
                type={'text'}
                styleType={'default'}
                label={lang.extraEuAmount}
                placeholder={lang.amountPlaceholder}
                onChange={(e) => {
                  dispatch(
                    setFeesRegisterAmountExtraEu(parseNumber(e.target.value))
                  );
                }}
                defaultValue={formatNumberIT(feesRegister.amount_EXTRA_EU)}
              />
            </div>
          </div>
          <div className="row mt-3 justify-content-center align-items-center">
            <div className="col-6 d-flex justify-content-center align-items-center">
              {editStatus === 'loading' || saveStatusSlice === 'loading' ? (
                <QuickfiscoSpinner />
              ) : (
                <QuickfiscoButton
                  id={'fees-register-editOrSave'}
                  type={
                    !editFeesRegister.ateco || editFeesRegister.ateco === ''
                      ? 'disabled'
                      : 'secondary'
                  }
                  label={lang.buttonLabel}
                  onClick={() =>
                    saveStatus
                      ? dispatch(
                          feesRegisterSave({
                            feesRegister: {
                              ...editFeesRegister,
                              date: feesRegister.date,
                            },
                          })
                        ).then(() => {
                          dispatch(
                            feesRegisterFindAll({
                              month: feesRegisterState.monthFilter,
                              year: feesRegisterState.yearFilter,
                            })
                          );
                          setOpen(false);
                          dispatch(setFeesRegisterFindByIdStatus('idle'));
                          dispatch(
                            setResetFeesRegister(
                              initialState.feesRegisterToEdit
                            )
                          );
                        })
                      : dispatch(
                          feesRegisterEdit({
                            id: feesRegister.id,
                            feesRegister: editFeesRegister,
                          })
                        ).then(() => {
                          dispatch(
                            feesRegisterFindAll({
                              month: feesRegisterState.monthFilter,
                              year: feesRegisterState.yearFilter,
                            })
                          );
                          setOpen(false);
                          dispatch(setFeesRegisterFindByIdStatus('idle'));
                          dispatch(
                            setResetFeesRegister(
                              initialState.feesRegisterToEdit
                            )
                          );
                        })
                  }
                />
              )}
            </div>
          </div>
        </div>
      )}
    </QuickfiscoModal>
  );
}

import React, { useState } from 'react';
import { v4 as uiidv4 } from 'uuid';
import {
  GoodServiceOperation,
  GoodServiceUnit,
  GoodServicesModel,
} from '../../models/invoiceModel';
import { InvoiceSign } from '../../types/strings';
import { formatNumberIT, parseNumber } from '../../utils/number';
import { ExpenseTypeFlagSelect } from '../expenseTypeFlagSelect/expenseTypeFlagSelect';
import { ExpenseTypeSelect } from '../expenseTypeSelect/expenseTypeSelect';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { VatNatureSelect } from '../vatNatureSelect/vatNatureSelect';
import './goodServices.css';
import lang from './goodServices.json';
import { AdjustmentModal } from '../adjustmentModal/adjustmentModal';

interface Props {
  goodServices: GoodServicesModel[];
  setGoodServices: (goodServices: GoodServicesModel[]) => void;
  invoiceSign: InvoiceSign;
  error?: boolean;
  errorLabel?: string;
  disabled?: boolean;
  stsIsActive?: boolean;
}

export function GoodServicesInput(props: Props) {
  const { goodServices } = props;
  const { setGoodServices } = props;
  const { invoiceSign } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { disabled } = props;
  const { stsIsActive } = props;

  const items = goodServices.map((_, index) => {
    return getItem(
      index,
      setGoodServices,
      goodServices,
      disabled,
      invoiceSign,
      stsIsActive
    );
  });

  const [openAdjustmentModal, setOpenAdjustmentModal] = useState(false);

  return (
    <div>
      {items}
      <div className={'row'}>
        {invoiceSign !== 'occasionalJob' && (
          <>
            <div className={'col-6'}>
              <QuickfiscoButton
                id={'invoice-customer-save-add-product-button'}
                label={lang.AddButton}
                onClick={() =>
                  setGoodServices([
                    ...goodServices,
                    {
                      key: uiidv4(),
                      quantity: 1,
                      amount: 0,
                    },
                  ])
                }
                type={disabled ? 'disabled' : 'tertiary'}
              />
            </div>
            {!(
              invoiceSign === 'sts' ||
              (invoiceSign === 'proforma' && stsIsActive)
            ) && (
              <div className={'col-6'}>
                <QuickfiscoButton
                  id={'invoice-customer-save-add-product-button'}
                  label={lang.AddAdjusted}
                  onClick={() => {
                    setOpenAdjustmentModal(true);
                  }}
                  type={
                    disabled ||
                    goodServices[0].description === undefined ||
                    goodServices[0].description === '' ||
                    isNaN(goodServices[0].amount) ||
                    goodServices[0].amount <= 0
                      ? 'disabled'
                      : 'primary'
                  }
                />
              </div>
            )}
          </>
        )}
        <div className={'col-6'}>
          {error && errorLabel && (
            <div className={'good-services-error'}>{errorLabel}</div>
          )}
        </div>
        <AdjustmentModal
          goodServices={goodServices}
          setGoodServices={setGoodServices}
          open={openAdjustmentModal}
          setOpen={setOpenAdjustmentModal}
        />
      </div>
    </div>
  );
}

function getItem(
  index: number,
  setGoodServices: (goodServices: GoodServicesModel[]) => void,
  goodServices: GoodServicesModel[],
  disabled?: boolean,
  invoiceSign?: InvoiceSign,
  stsIsActive?: boolean
): React.ReactNode {
  return (
    <div className={'row'} key={'item-' + goodServices[index].key}>
      <div className="col-12">
        <div className="row">
          <div className="col-12">
            <span className="good-services-input-title">
              {index + 1}. Prodotto/servizio
            </span>
          </div>
        </div>
        <div className="row">
          <div className={'col'}>
            <QuickfiscoInput
              id={'invoice-customer-product-description'}
              type={'text'}
              styleType={'default'}
              label={'Descrizione'}
              placeholder={'Max 1000 caratteri'}
              maxLength={1000}
              disabled={disabled}
              onChange={(e) => {
                const newGoodServices = [...goodServices];
                newGoodServices[index] = {
                  ...goodServices[index],
                  description: e.target.value,
                };
                setGoodServices([...newGoodServices]);
              }}
              value={goodServices[index].description || ''}
              required={true}
            />
          </div>
          {invoiceSign !== 'occasionalJob' && (
            <div className={'col-2 invoice-customer-product-quantity'}>
              <QuickfiscoInput
                id={'invoice-customer-product-quantity'}
                type={'number'}
                label={'Q.tà'}
                styleType={'default'}
                placeholder={'0'}
                disabled={disabled}
                onChange={(e) => {
                  const newGoodServices = [...goodServices];

                  newGoodServices[index] = {
                    ...goodServices[index],
                    quantity: Number(e.target.value),
                  };

                  setGoodServices([...newGoodServices]);
                }}
                defaultValue={String(goodServices[index].quantity)}
                required={true}
              />
            </div>
          )}
          <div className={'col-2 invoice-customer-product-amount'}>
            <QuickfiscoInput
              id={'invoice-customer-product-amount'}
              type={'text'}
              label={'Importo'}
              styleType={'default'}
              placeholder={'0.00€'}
              disabled={disabled}
              onChange={(e) => {
                const newGoodServices = [...goodServices];

                newGoodServices[index] = {
                  ...goodServices[index],
                  amount: parseNumber(e.target.value),
                };

                setGoodServices([...newGoodServices]);
              }}
              defaultValue={formatNumberIT(goodServices[index].amount)}
              required={true}
            />
          </div>
          {invoiceSign === 'auto' && (
            <div className={'col-4 invoice-customer-product-vat-nature'}>
              <VatNatureSelect
                id="good-service-input-vat-nature-select"
                // @ts-ignore
                value={
                  goodServices[index].vatNature === null
                    ? undefined
                    : goodServices[index].vatNature
                }
                disabled={disabled}
                onChange={(_vatNature) => {
                  const newGoodServices = [...goodServices];
                  newGoodServices[index] = {
                    ...goodServices[index],
                    vatNature: _vatNature,
                  };
                  setGoodServices([...newGoodServices]);
                }}
              />
            </div>
          )}
          {!disabled && invoiceSign !== 'occasionalJob' && (
            <div className={'col-auto no-gutters d-flex align-items-center'}>
              <QuickfiscoIcon
                name={'delete-icon.svg'}
                isButton={true}
                onClick={() => {
                  const newGoodService = goodServices
                    .slice(0, index)
                    .concat(goodServices.slice(index + 1));

                  setGoodServices([...newGoodService]);
                }}
              />
            </div>
          )}
        </div>
        {goodServices[index].adjustment && (
          <div className="row good-services-adjustment-bg ms-auto">
            <div className={'col d-flex align-items-center'}>
              <QuickfiscoIcon
                name={'delete-icon.svg'}
                isButton={!disabled}
                onClick={() => {
                  if (!disabled) {
                    const updatedGoodServices = [...goodServices];
                    updatedGoodServices[index] = {
                      ...updatedGoodServices[index],
                      adjustment: undefined,
                    };

                    setGoodServices(updatedGoodServices);
                  }
                }}
              />
              <span className="good-services-adjustment-title">
                {goodServices[index].adjustment?.type ===
                GoodServiceOperation.SC
                  ? 'Sconto'
                  : 'Maggiorazione'}
              </span>
            </div>
            <div className={'col-2 p-0 good-services-adjustment-unit'}>
              <span>
                {goodServices[index].adjustment?.unit === GoodServiceUnit.FIXED
                  ? '€'
                  : '%'}
              </span>
            </div>
            <div className={'col-2 p-0 good-services-adjustment-amount'}>
              <span>{goodServices[index].adjustment?.amount}</span>
            </div>
          </div>
        )}
        {(invoiceSign === 'sts' ||
          (invoiceSign === 'proforma' && stsIsActive)) && (
          <div className="row mb-4">
            <div className="col-6">
              <ExpenseTypeSelect
                id="good-service-input-expense-type"
                onChange={(_expenseType) => {
                  const newGoodServices = [...goodServices];
                  newGoodServices[index] = {
                    ...goodServices[index],
                    expenseType: _expenseType,
                  };
                  setGoodServices([...newGoodServices]);
                }}
                disabled={disabled}
                // @ts-ignore
                value={
                  goodServices[index].expenseType === null
                    ? undefined
                    : goodServices[index].expenseType
                }
              />
            </div>
            <div className="col-6">
              <ExpenseTypeFlagSelect
                id="good-service-input-expense-flag-type"
                onChange={(_expenseTypeFlag) => {
                  const newGoodServices = [...goodServices];
                  newGoodServices[index] = {
                    ...goodServices[index],
                    expenseTypeFlag: _expenseTypeFlag,
                  };
                  setGoodServices([...newGoodServices]);
                }}
                disabled={disabled}
                // @ts-ignore
                value={
                  goodServices[index].expenseTypeFlag === null
                    ? undefined
                    : goodServices[index].expenseTypeFlag
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

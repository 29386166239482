import { useState } from 'react';
import { SendResetPasswordEmailModel } from '../../models/sendResetPasswordEmailModel';
import { UserService } from '../../services/userService';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import './passwordRecovery.css';
import lang from './passwordRecovery.json';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function PasswordRecovery(props: Props) {
  const { open } = props;
  const { setOpen } = props;

  const [email, setEmail] = useState<string>('');
  const [errorEmail, setErrorEmail] = useState(false);
  const [resetPasswordStatus, setResetPasswordStatus] =
    useState<PromiseStatuses>('idle');

  if (resetPasswordStatus === 'successfully') {
    return (
      <QuickfiscoModal isOpen={open} hide={() => setOpen(false)}>
        <div className={'p-5 pt-0 text-center'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <QuickfiscoIcon name={'alert-success.svg'} />
              <div className={'password-recovery-title my-4'}>
                {lang.titleStep2}
              </div>
            </div>
          </div>
          <div className={'row'}>
            <div className={'col-12'}>
              <div className={'password-recovery-subtitle'}>
                {lang.subtitleStep2}
              </div>
            </div>
          </div>
          <div className={'row mb-3'}>
            <div className={'col-12'}>
              <div className={'password-recovery-subtitle'}>
                <span>{email}</span>
              </div>
            </div>
          </div>
        </div>
      </QuickfiscoModal>
    );
  }

  return (
    <QuickfiscoModal isOpen={open} hide={() => setOpen(false)}>
      <div className={'p-2 pt-0'}>
        <QuickfiscoError
          message={lang.errors.resetPassword}
          active={resetPasswordStatus === 'failed'}
          close={() => setResetPasswordStatus('idle')}
        />
        <div className={'row'}>
          <div className={'col-12 mt-3 mb-2'}>
            <div className={'password-recovery-title'}>{lang.title}</div>
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-12 mb-3'}>
            <div className={'password-recovery-subtitle'}>{lang.subtitle}</div>
          </div>
        </div>
        <div className={'row my-4'}>
          <div className={'col-12'}>
            <QuickfiscoInput
              id={'password-recovery-email-input'}
              type={'text'}
              placeholder={lang.placeholder}
              label={''}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              error={errorEmail}
              errorLabel={lang.errorEmail}
              styleType={'no-borders'}
            />
          </div>
        </div>
        <div className={'row mt-2 mb-5'}>
          <div className={'col-4 offset-8'}>
            {resetPasswordStatus === 'loading' ? (
              <div
                className={
                  'd-flex justify-content-center align-items-center w-100'
                }
              >
                <QuickfiscoSpinner />
              </div>
            ) : (
              <QuickfiscoButton
                id={'password-recovery-email-save'}
                label={lang.button}
                type={'secondary'}
                onClick={() =>
                  sendResetPasswordEmail(
                    { email },
                    setResetPasswordStatus,
                    setErrorEmail
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
    </QuickfiscoModal>
  );
}

function sendResetPasswordEmail(
  request: SendResetPasswordEmailModel,
  setStatus: SetState<PromiseStatuses>,
  setErrorEmail: SetState<boolean>
): void {
  if (!request.email) {
    setErrorEmail(true);
    return;
  }

  const service = new UserService();

  setStatus('loading');
  service
    .sendResetPasswordEmail(request)
    .then(() => setStatus('successfully'))
    .catch((err) => {
      setStatus('failed');
      console.error(err);
    });
}

import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { feesRegisterFindAll } from '../../redux/slices/feesRegisterSlice';
import { FeesRegisterTableRow } from '../feesRegisterTableRow/feesRegisterTableRow';
import { FeesRegisterTotalAmount } from '../feesRegisterTotalAmount/feesRegisterTotalAmount';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './feesRegisterTable.css';
import { checkLeapYear, daysPerMonth } from '../../utils/date';
import { FeesRegisterModel } from '../../models/feesRegisterModel';

export function FeesRegisterTable() {
    const dispatch = useAppDispatch();

    const feesRegisterState = useAppSelector(state => state.feesRegister)

    const month = feesRegisterState.monthFilter;
    const year = feesRegisterState.yearFilter;

    useEffect(() => {
        dispatch(feesRegisterFindAll({
            month,
            year
        }))
    }, [month, year])

    if (feesRegisterState.findAllStatus === 'loading') {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <QuickfiscoSpinner />
            </div>
        );
    }

    if (feesRegisterState.findAllStatus === 'failed') {
        return (
            <div className="d-flex justify-content-center align-items-center">
                Errore durante il caricamento dei dati
            </div>
        );
    }

    const itemsList: React.ReactNode[] = [];

    let daysThisMonth: number | undefined

    if (checkLeapYear(Number(year))) {
        daysPerMonth.set(1, 29)
    }

    if (month !== undefined) {
        daysThisMonth = daysPerMonth.get(Number(month) - 1)
    }

    const feesRegisterList = feesRegisterState.feesRegisterList;

    if (daysThisMonth) {
        const days = Array.from({ length: daysThisMonth }, (_, index) => index + 1);
        days.forEach((day) => {
            let feesRegister: FeesRegisterModel | undefined = undefined;

            feesRegisterList.forEach((feesRegisterRow) => {
                const feesRegisterRowDate = new Date(feesRegisterRow.date).setHours(0, 0, 0, 0);
                const targetDate = new Date(Number(year), Number(month) - 1, day).setHours(0, 0, 0, 0);

                if (feesRegisterRowDate === targetDate) {
                    feesRegister = feesRegisterRow;
                }
            });

            if (!feesRegister) {
                feesRegister = {
                    date: new Date(Date.UTC(Number(year), Number(month) - 1, day)),
                    ateco: '',
                    amount: 0,
                    amount_EU: 0,
                    amount_EXTRA_EU: 0,
                    amount_IT: 0,
                };
            }

            itemsList.push(
                <FeesRegisterTableRow
                    key={feesRegister.id}
                    feesRegister={feesRegister}
                />
            );
        });
    }

    return (
        <div className="row no-gutters fees-register-table-container">
            <div className='col-12'>
                <div className='row'>
                    <div className='col-12 fees-register-table'>
                        <div className="row fees-register-table-border-bottom-dark">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-2 fees-register-table-cells fees-register-table-border-right">
                                        <span>
                                            Giorno
                                        </span>
                                    </div>
                                    <div className="col-2 fees-register-table-cells fees-register-table-border-right">
                                        <span>
                                            Totale corrispettivi giornalieri
                                        </span>
                                    </div>
                                    <div className="col-3 fees-register-table-cells fees-register-table-border-right">
                                        <span>
                                            Codice IVA
                                        </span>
                                    </div>
                                    <div className="col fees-register-table-border-right">
                                        <div className="row">
                                            <div className="col-12 fees-register-table-cells fees-register-table-border-bottom-light">
                                                <span>
                                                    Country
                                                </span>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4 fees-register-table-cells fees-register-table-border-right">
                                                <span>
                                                    ITA
                                                </span>
                                            </div>
                                            <div className="col-4 fees-register-table-cells fees-register-table-border-right">
                                                <span>
                                                    UE
                                                </span>
                                            </div>
                                            <div className="col-4 fees-register-table-cells">
                                                <span>
                                                    EXTRA UE
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-auto fees-register-table-cells">
                                        <div style={{ width: '45px' }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                {itemsList}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <FeesRegisterTotalAmount />
                    </div>
                </div>
            </div>
        </div>
    )
}

import { useEffect, useState } from 'react';
import { useProSidebar } from 'react-pro-sidebar';
import { useNavigate } from 'react-router-dom';
import { InvoiceConfirmImportModal } from '../../components/invoiceConfirmImportModal/invoiceConfirmImportModal';
import { InvoiceImportListComponent } from '../../components/invoiceImportListComponent/invoiceImportListComponent';
import { QuickfiscoButton } from '../../components/quickfiscoButton/quickfiscoButton';
import { QuickfiscoError } from '../../components/quickfiscoError/quickfiscoError';
import { QuickfiscoHeader } from '../../components/quickfiscoHeader/quickfiscoHeader';
import {
  MenuItems,
  QuickfiscoMenu,
} from '../../components/quickfiscoMenu/quickfiscoMenu';
import { QuickfiscoSuccess } from '../../components/quickfiscoSuccess/quickfiscoSuccess';
import { ViewportWarning } from '../../components/viewportWarning/viewportWarning';
import {
  InvoiceModel,
  InvoiceStatusType,
  InvoiceType,
} from '../../models/invoiceModel';
import { useAppDispatch } from '../../redux/hooks';
import { setImportInvoicesReset } from '../../redux/slices/userSlice';
import uri from '../../routers/quickfiscoUri.json';
import { ImportInvoiceService } from '../../services/importInvoiceService';
import { PromiseStatuses } from '../../types/strings';
import './invoiceImportList.css';
import lang from './invoiceImportList.json';

export function InvoiceImportList() {
  const [open, setOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [statusImport, setStatusImport] = useState<PromiseStatuses>('idle');
  const [statusSaveMultiPayments, setStatusSaveMultiPayments] =
    useState<PromiseStatuses>('idle');
  const [statusSaving, setStatusSaving] = useState<PromiseStatuses>('idle');
  const [checkedPrevoiusYearEnabled, setCheckedPrevoiusYearEnabled] =
    useState<boolean>(true);
  const [checkedThisYearEnabled, setCheckedThisYearEnabled] =
    useState<boolean>(true);

  const [idInvoicesFromPaid, setIdInvoicesFromPaid] = useState<string[]>([]);
  const [invoices, setInvoices] = useState<InvoiceModel[]>([]);

  const { collapsed } = useProSidebar();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const findAllCustomer = () => {
    const service = new ImportInvoiceService();

    setStatus('loading');
    service
      .findAllCustomer()
      .then((data) => {
        setInvoices(data.content);
        setStatus('successfully');
      })
      .catch((err) => {
        setStatus('failed');
        console.error(err);
      });
  };

  const importInvoices = () => {
    const service = new ImportInvoiceService();

    setStatusImport('loading');
    service
      .importCustomer()
      .then(() => {
        setStatusImport('successfully');
        dispatch(setImportInvoicesReset());
        navigate(uri.CustomerInvoiceList);
      })
      .catch((err) => {
        setStatusImport('failed');
        console.error(err);
      });
  };

  const multiPayments = (invoicesIds: string[], year: number) => {
    const service = new ImportInvoiceService();

    setStatusSaveMultiPayments('loading');
    service
      .saveMultiPayments(invoicesIds, year)
      .then(() => {
        setStatusSaveMultiPayments('successfully');
      })
      .catch((err) => {
        setStatusSaveMultiPayments('failed');
        console.error(err);
      });
  };

  const isAnyInvoiceNotFromPreviousYear = invoices.some((invoice) => {
    const invoiceDate = new Date(invoice.date);
    const invoiceType = invoice.type;
    return (
      invoiceType !== InvoiceType.TD04 &&
      invoiceDate.getFullYear() <= new Date().getFullYear() - 1
    );
  });

  const isAnyInvoiceNotFromThisYear = invoices.some((invoice) => {
    const invoiceDate = new Date(invoice.date);
    const invoiceType = invoice.type;
    return (
      invoiceType !== InvoiceType.TD04 &&
      invoiceDate.getFullYear() <= new Date().getFullYear()
    );
  });

  const AllInvoicePaidPreviousYear = invoices.some((invoice) => {
    const invoiceStatus = invoice.status;
    const invoiceDate = new Date(invoice.date);
    const invoiceType = invoice.type;
    return (
      invoiceType !== InvoiceType.TD04 &&
      invoiceStatus !== InvoiceStatusType.PAID &&
      invoiceDate.getFullYear() <= new Date().getFullYear() - 1
    );
  });

  const AllInvoicePaidThisYear = invoices.some((invoice) => {
    const invoiceStatus = invoice.status;
    const invoiceDate = new Date(invoice.date);
    const invoiceType = invoice.type;
    return (
      invoiceType !== InvoiceType.TD04 &&
      invoiceStatus !== InvoiceStatusType.PAID &&
      invoiceDate.getFullYear() <= new Date().getFullYear()
    );
  });

  useEffect(() => {
    if (
      statusSaveMultiPayments === 'successfully' ||
      statusSaving === 'successfully'
    )
      findAllCustomer();
  }, [statusSaveMultiPayments, statusSaving]);

  useEffect(() => {
    findAllCustomer();
  }, []);

  return (
    <div className="full-screen bg-blue">
      <QuickfiscoError
        message={lang.failedStatus}
        active={statusSaveMultiPayments === 'failed'}
        close={() => setStatusSaveMultiPayments('idle')}
      />
      <QuickfiscoSuccess
        message={lang.successfullyStatus}
        active={statusSaveMultiPayments === 'successfully'}
        close={() => setStatusSaveMultiPayments('idle')}
      />
      <ViewportWarning />
      <div className="row no-gutters">
        <div className="col-auto no-gutters bg-blue">
          <QuickfiscoMenu
            id="customer-list-menu"
            menuItem={MenuItems.CUSTOMER_INVOICE}
          />
        </div>
        <div
          className={'col p-4 ps-5 invoice-importList-scroll-y'}
          style={{
            marginLeft: collapsed ? '120px' : '280px',
            transition: 'all .2s ease-in-out',
          }}
        >
          <div className={'row'}>
            <div className={'col'}>
              <QuickfiscoHeader title={lang.Title} />
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <div className="invoice-importList-warn-box">
                {lang.warn_box_1}
                <b>{lang.warn_box_2}</b>
                {lang.warn_box_3}
                <br />
                {lang.warn_box_3_1}
                <br />
              </div>
            </div>
          </div>
          <div className={'row mt-2'}>
            <div className={'col-12'}>
              <div className="invoice-importList-warn-box">
                {lang.warn_box_4}
                {new Date().getFullYear() - 1} e {new Date().getFullYear()}.
                <br />
                {lang.warn_box_5}
                <b>{lang.warn_box_6}</b>
                {lang.warn_box_7}
                <br />
                {lang.warn_box_8}
                <br />
              </div>
            </div>
          </div>
          <div className={'row mt-4 justify-content-center'}>
            {isAnyInvoiceNotFromPreviousYear === true &&
              AllInvoicePaidPreviousYear === true && (
                <div className={'col-4 d-flex justify-content-center'}>
                  <QuickfiscoButton
                    id={'invoice-supplier-saveOrEdit-add-supplier'}
                    type={
                      checkedPrevoiusYearEnabled === false
                        ? 'disabled'
                        : 'secondary'
                    }
                    label={`${lang.multiPaymentButton} ${
                      new Date().getFullYear() - 1
                    }`}
                    onClick={() => {
                      setCheckedPrevoiusYearEnabled(
                        !checkedPrevoiusYearEnabled
                      );
                      if (
                        isAnyInvoiceNotFromThisYear === true ||
                        AllInvoicePaidThisYear === true
                      ) {
                        setCheckedThisYearEnabled(true);
                      }
                    }}
                  />
                </div>
              )}
            {isAnyInvoiceNotFromThisYear === true &&
              AllInvoicePaidThisYear === true && (
                <div className={'col-4 d-flex justify-content-center'}>
                  <QuickfiscoButton
                    id={'invoice-supplier-saveOrEdit-add-supplier'}
                    type={
                      checkedThisYearEnabled === false
                        ? 'disabled'
                        : 'secondary'
                    }
                    label={`${
                      lang.multiPaymentButton
                    } ${new Date().getFullYear()}`}
                    onClick={() => {
                      setCheckedThisYearEnabled(!checkedThisYearEnabled);
                      if (
                        isAnyInvoiceNotFromPreviousYear === true ||
                        AllInvoicePaidPreviousYear === true
                      ) {
                        setCheckedPrevoiusYearEnabled(true);
                      }
                    }}
                  />
                </div>
              )}
          </div>
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <InvoiceImportListComponent
                invoiceList={invoices}
                status={status}
                type="customer"
                checkedPrevoiusYearEnabled={checkedPrevoiusYearEnabled}
                checkedThisYearEnabled={checkedThisYearEnabled}
                idInvoicesFromPaid={idInvoicesFromPaid}
                setIdInvoicesFromPaid={setIdInvoicesFromPaid}
                setStatusSaving={setStatusSaving}
                statusSaving={statusSaving}
              />
            </div>
          </div>
        </div>
        <div className="row no-gutters">
          <div
            className="col ps-5"
            style={{
              marginLeft: collapsed ? '120px' : '280px',
              transition: 'all .2s ease-in-out',
            }}
          >
            <div className={'row m-4 justify-content-center'}>
              <div className={'col-10 d-flex justify-content-center'}>
                <div className="invoice-importList-text-footer">
                  <b>Dopo aver controllato i dati delle fatture</b> e aver
                  integrato con quanto richiesto ad es. il codice ateco, lo
                  stato di pagamento e relativo anno, potrai procedere con
                  l’import. L’azione sarà definitiva,{' '}
                  <b>se hai dubbi scrivi in chat al servizio clienti.</b>
                </div>
              </div>
            </div>
            <div className={'row mt-2 justify-content-center'}>
              {(checkedPrevoiusYearEnabled && checkedThisYearEnabled) ||
                ((checkedPrevoiusYearEnabled === false ||
                  checkedThisYearEnabled === false ||
                  (AllInvoicePaidThisYear === true &&
                    AllInvoicePaidPreviousYear === true)) && (
                  <div className={'col-4 d-flex justify-content-center'}>
                    <QuickfiscoButton
                      id={'invoice-supplier-saveOrEdit-add-supplier'}
                      type={'senary'}
                      label={`${lang.paymentButton} ${
                        checkedPrevoiusYearEnabled
                          ? new Date().getFullYear()
                          : new Date().getFullYear() - 1
                      }`}
                      onClick={() => {
                        multiPayments(
                          idInvoicesFromPaid,
                          checkedPrevoiusYearEnabled
                            ? new Date().getFullYear()
                            : new Date().getFullYear() - 1
                        );
                        setIdInvoicesFromPaid([]);
                        setCheckedPrevoiusYearEnabled(true);
                        setCheckedThisYearEnabled(true);
                      }}
                    />
                  </div>
                ))}
              <div className={'col-4'}>
                <QuickfiscoButton
                  id={'invoiceImportListRow-import-btn'}
                  type={'secondary'}
                  label={lang.importBtn}
                  onClick={() => setOpen(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <InvoiceConfirmImportModal
        open={open}
        setOpen={(open: boolean) => setOpen(open)}
        status={statusImport}
        importInvoices={importInvoices}
        type="customer"
      />
    </div>
  );
}

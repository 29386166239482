import { isAfter } from 'date-fns';
import { v4 as uiidv4 } from 'uuid';
import { fundsMap } from '../components/fundSelect/fundSelect';
import { ChangeInvoiceStatusModel } from '../models/changeInvoiceStatusModel';
import { ContactModel } from '../models/contactModel';
import { InvoiceErrorSetters } from '../models/invoiceErrorSetters';
import {
  InvoiceFilters,
  InvoiceStatusFiltersType,
} from '../models/invoiceFilters';
import {
  GoodServiceOperation,
  GoodServiceUnit,
  GoodServicesModel,
  InvoiceModel,
  InvoiceType,
  ReimbursementOfExpenseModel,
  VatNature,
} from '../models/invoiceModel';
import { InvoiceReportModel } from '../models/invoiceReportModel';
import { InvoiceSummaryModel } from '../models/invoiceSummaryModel';
import { ObjectType } from '../models/objectModel';
import { PaginationModel } from '../models/paginationModel';
import { StsInvoiceReportModel } from '../models/stsInvoiceReportReportModel';
import { ExpenseType } from '../models/stsProfileModel';
import {
  TotalInvoicePaidMap,
  TotalRevenuesModel,
} from '../models/totalRevenuesModel';
import { UserModel } from '../models/userModel';
import { InvoiceSign } from '../types/strings';
import { removeTime } from '../utils/date';
import { getExtension, toBase64, toFile } from '../utils/file';
import { Http } from '../utils/http';
import { roundToTwoDigitDecimal } from '../utils/number';
import { ObjectService } from './objectService';
import config from '../../config.json';
import { customNumerationRegex } from '../regex/regex';

export class CommonInvoiceService {
  public static readonly stampAmount = 2;
  public static readonly stampActivationThreshold = 77.47;

  private static readonly pdfNameMap: Map<string, string> = new Map([
    [
      JSON.stringify({
        sign: 'customer',
        type: InvoiceType.TD01,
      }),
      'Fattura-',
    ],
    [
      JSON.stringify({
        sign: 'customer',
        type: InvoiceType.TD02,
      }),
      'Fattura-',
    ],
    [
      JSON.stringify({
        sign: 'customer',
        type: InvoiceType.TD03,
      }),
      'Fattura-',
    ],
    [
      JSON.stringify({
        sign: 'customer',
        type: InvoiceType.TD06,
      }),
      'Fattura-',
    ],
    [
      JSON.stringify({
        sign: 'customer',
        type: InvoiceType.TD24,
      }),
      'Fattura-',
    ],
    [
      JSON.stringify({
        sign: 'customer',
        type: InvoiceType.TD25,
      }),
      'Fattura-',
    ],
    [
      JSON.stringify({
        sign: 'customer',
        type: InvoiceType.TD26,
      }),
      'Fattura-',
    ],
    [
      JSON.stringify({
        sign: 'supplier',
        type: InvoiceType.TD01,
      }),
      'Fattura-',
    ],
    [
      JSON.stringify({
        sign: 'customer',
        type: InvoiceType.TD04,
      }),
      'Nota-di-credito-',
    ],
    [
      JSON.stringify({
        sign: 'sts',
        type: undefined,
      }),
      'Fattura STS-',
    ],
    [
      JSON.stringify({
        sign: 'auto',
        type: undefined,
      }),
      'Auto fattura-',
    ],
    [
      JSON.stringify({
        sign: 'proforma',
        type: InvoiceType.TD01,
      }),
      'Proforma-',
    ],
    [
      JSON.stringify({
        sign: 'occasionalJob',
        type: undefined,
      }),
      'Prestazione-occasionale-',
    ],
  ]);

  protected static readonly vatRateMap = new Map<VatNature, number>([
    [VatNature.N1, 0],
    [VatNature.N2_1, 0],
    [VatNature.N2_2, 0],
    [VatNature.N3_1, 0],
    [VatNature.N3_2, 0],
    [VatNature.N3_3, 0],
    [VatNature.N3_4, 0],
    [VatNature.N3_5, 0],
    [VatNature.N3_6, 0],
    [VatNature.N4, 0],
    [VatNature.N5, 0],
    [VatNature.N6_1, 0],
    [VatNature.N6_2, 0],
    [VatNature.N6_3, 0],
    [VatNature.N6_4, 0],
    [VatNature.N6_5, 0],
    [VatNature.N6_6, 0],
    [VatNature.N6_7, 0],
    [VatNature.N6_8, 0],
    [VatNature.N6_9, 0],
    [VatNature.N7, 0],
    [VatNature.NS_4, 4],
    [VatNature.NS_5, 5],
    [VatNature.NS_10, 10],
    [VatNature.NS_22, 22],
  ]);

  private readonly objectService: ObjectService;

  protected readonly http: Http;
  protected readonly url: string;
  protected readonly errorSetters?: InvoiceErrorSetters;

  protected constructor(url: string, errorSetters?: InvoiceErrorSetters) {
    this.url = url;
    this.http = Http.getInstance();
    this.errorSetters = errorSetters;
    this.objectService = new ObjectService();
  }

  protected static setGoodServiceKey(invoice: InvoiceModel): InvoiceModel {
    const output = { ...invoice };

    if (output.goodServices !== undefined && output.goodServices !== null) {
      output.goodServices = output.goodServices.map((goodService) => {
        return {
          ...goodService,
          key: uiidv4(),
        };
      });
    }

    return output;
  }

  public static union(
    filters: InvoiceFilters,
    customerInvoices: PaginationModel<InvoiceModel>,
    stsInvoices: PaginationModel<InvoiceModel>
  ): PaginationModel<InvoiceModel> {
    const output: PaginationModel<InvoiceModel> = { content: [] };

    if (
      filters.type === InvoiceType.TD04 ||
      filters.status === InvoiceStatusFiltersType.TO_PAY ||
      filters.status === InvoiceStatusFiltersType.EXPIRED
    ) {
      output.content = [...customerInvoices.content];
    } else {
      output.content = [...customerInvoices.content, ...stsInvoices.content];
    }

    return output;
  }

  public static filter(
    sign: InvoiceSign,
    filters: InvoiceFilters,
    invoices: PaginationModel<InvoiceModel>
  ): PaginationModel<InvoiceModel> {
    const output = { ...invoices };

    const companyName = filters.companyName;

    if (companyName === undefined || companyName === '') {
      return output;
    }

    if (
      sign === 'customer' ||
      sign === 'sts' ||
      sign === 'proforma' ||
      sign === 'occasionalJob'
    ) {
      output.content = output.content.filter((invoice) =>
        invoice.customer?.companyName
          ?.toLowerCase()
          .includes(companyName.toLowerCase())
      );
    } else {
      output.content = output.content.filter((invoice) =>
        invoice.supplier?.companyName
          ?.toLowerCase()
          .includes(companyName.toLowerCase())
      );
    }

    return output;
  }

  public static sort(
    invoices: PaginationModel<InvoiceModel>
  ): PaginationModel<InvoiceModel> {
    let output: PaginationModel<InvoiceModel> = { content: [] };

    let rowsNumber: InvoiceModel[] = [];
    invoices.content.forEach((invoice) => {
      if (invoice.number !== null) {
        rowsNumber.push(invoice);
        rowsNumber.sort((a, b) => Number(b.number) - Number(a.number));
      }
    });

    let rowsNotNumber: InvoiceModel[] = [];
    invoices.content.forEach((invoice) => {
      if (invoice.number === null) {
        rowsNotNumber.push(invoice);
      }
    });
    rowsNotNumber.reverse();

    rowsNotNumber.forEach((invoice) => {
      output.content.push(invoice);
    });

    rowsNumber.forEach((invoice) => {
      output.content.push(invoice);
    });

    return output;
  }

  public static paginate<T>(
    page: number,
    pagesize: number,
    invoices: PaginationModel<T>
  ): PaginationModel<T> {
    const output: PaginationModel<T> = { ...invoices };

    output.number = page;
    output.first = page === 0;
    output.empty = invoices.content.length === 0;
    output.totalPages = Math.ceil(output.content.length / pagesize);
    output.last = page === output.totalPages - 1;

    const indexFrom = page * pagesize;
    const indexTo = page * pagesize + pagesize;

    output.content = output.content.slice(indexFrom, indexTo);

    return output;
  }

  public static mergeTotalRevenues(
    x: TotalRevenuesModel,
    y: TotalRevenuesModel,
    z: TotalRevenuesModel
  ): TotalRevenuesModel {
    // Calculate total invoice paid grouped by ateco

    const totalInvoicesPaidGroupedByAteco: TotalInvoicePaidMap = {};

    if (x.totalInvoicesPaid !== undefined) {
      for (let key in x.totalInvoicesPaid) {
        const totalValue = x.totalInvoicesPaid[key];

        if (totalInvoicesPaidGroupedByAteco[key] === undefined) {
          totalInvoicesPaidGroupedByAteco[key] = totalValue;

          continue;
        }

        totalInvoicesPaidGroupedByAteco[key] += totalValue;
      }
    }
    if (y.totalInvoicesPaid !== undefined) {
      for (let key in y.totalInvoicesPaid) {
        const totalValue = y.totalInvoicesPaid[key];

        if (totalInvoicesPaidGroupedByAteco[key] === undefined) {
          totalInvoicesPaidGroupedByAteco[key] = totalValue;

          continue;
        }

        totalInvoicesPaidGroupedByAteco[key] += totalValue;
      }
    }
    if (z.totalInvoicesPaid !== undefined) {
      for (let key in z.totalInvoicesPaid) {
        const totalValue = z.totalInvoicesPaid[key];

        if (totalInvoicesPaidGroupedByAteco[key] === undefined) {
          totalInvoicesPaidGroupedByAteco[key] = totalValue;

          continue;
        }

        totalInvoicesPaidGroupedByAteco[key] += totalValue;
      }
    }

    // Calculate total invoice current year

    let totalInvoices = 0;
    if (x.totalInvoices !== undefined) {
      totalInvoices += x.totalInvoices;
    }
    if (y.totalInvoices !== undefined) {
      totalInvoices += y.totalInvoices;
    }
    if (z.totalInvoices !== undefined) {
      totalInvoices += z.totalInvoices;
    }

    // Calculate total invoice paid last year

    let totalInvoicesPaidLastYear = 0;
    if (x.totalInvoicesPaidLastYear !== undefined) {
      totalInvoicesPaidLastYear += x.totalInvoicesPaidLastYear;
    }
    if (y.totalInvoicesPaidLastYear !== undefined) {
      totalInvoicesPaidLastYear += y.totalInvoicesPaidLastYear;
    }
    if (z.totalInvoicesPaidLastYear !== undefined) {
      totalInvoicesPaidLastYear += z.totalInvoicesPaidLastYear;
    }

    // Check undefined regime limit

    let regimeLimit = 0;

    if (x.regimeLimit !== undefined) {
      regimeLimit += x.regimeLimit;
    }

    // Return merged total revenues

    const output: TotalRevenuesModel = {
      regimeLimit: regimeLimit,
      totalInvoices: totalInvoices,
      totalInvoicesPaid: totalInvoicesPaidGroupedByAteco,
      totalInvoicesPaidLastYear: totalInvoicesPaidLastYear,
      totalRemaining: 0,
    };
    // Calculate total remaining

    output.totalRemaining =
      regimeLimit -
      CommonInvoiceService.calculateTotalPaid(output).totalPaid -
      CommonInvoiceService.calculateTotalPaid(output).totalPaidLastYear;

    return output;
  }

  public static calculateTotalAmount(
    invoice: InvoiceModel,
    user: UserModel,
    invoiceSign: InvoiceSign
  ): number {
    if (invoiceSign === 'supplier') {
      if (invoice.amount === null || invoice.amount === undefined) {
        return 0;
      }

      return invoice.amount;
    }

    let reimbursementOfExpenseAmount = 0;
    if (
      invoice.reimbursementsOfExpensesAmount !== undefined &&
      invoice.reimbursementsOfExpensesAmount !== null
    ) {
      reimbursementOfExpenseAmount = invoice.reimbursementsOfExpensesAmount;
    }

    const stampAmount = CommonInvoiceService.calculateStamp(invoice);
    const taxBase = CommonInvoiceService.calculateTaxBase(invoice, false);
    const socialContributionAmount =
      user.fund === 'TC07'
        ? 0
        : CommonInvoiceService.calculateSocialContribution(invoice, user, true);
    const taxes = CommonInvoiceService.calculateTaxes(invoice);
    const withholding = invoice.withholding?.active
      ? CommonInvoiceService.calculateWithholding(invoice, true)
      : 0.0;
    const adjustmentTotal =
      CommonInvoiceService.calculateAdjustmentTotal(invoice);

    return roundToTwoDigitDecimal(
      stampAmount +
        taxBase +
        socialContributionAmount +
        reimbursementOfExpenseAmount -
        withholding +
        taxes +
        adjustmentTotal
    );
  }

  public static calculateTotalPaid(totalRevenues: TotalRevenuesModel): {
    totalPaid: number;
    totalPaidLastYear: number;
  } {
    let totalPaid: number = 0;
    let totalPaidLastYear: number = 0;

    const totalInvoicesPaid = totalRevenues.totalInvoicesPaid;
    const totalInvoicesPaidLastYear = totalRevenues.totalInvoicesPaidLastYear;

    if (totalInvoicesPaid !== undefined) {
      for (let key in totalInvoicesPaid) {
        totalPaid += totalInvoicesPaid[key];
      }
    }

    if (totalInvoicesPaidLastYear !== undefined) {
      totalPaidLastYear += totalInvoicesPaidLastYear;
    }

    return { totalPaid, totalPaidLastYear };
  }

  public static calculateStamp(invoice: InvoiceModel): number {
    return invoice.stamp ? this.stampAmount : 0;
  }

  public static calculateAdjustmentTotal(invoice: InvoiceModel): number {
    return (
      invoice.goodServices?.reduce((acc, service) => {
        const { adjustment, quantity, amount } = service;
        const adjustmentAmount = adjustment?.amount ?? 0;

        if (!adjustment) return acc;

        const factor =
          adjustment.unit === GoodServiceUnit.FIXED
            ? adjustmentAmount * quantity
            : ((amount * adjustmentAmount) / 100) * quantity;

        return adjustment.type === GoodServiceOperation.SC
          ? acc - factor
          : acc + factor;
      }, 0) ?? 0
    );
  }

  public static calculateTaxBase(
    invoice: InvoiceModel,
    adjustment: boolean
  ): number {
    let taxBase = 0;

    if (invoice.goodServices !== undefined && invoice.goodServices !== null) {
      for (const goodService of invoice.goodServices) {
        if (adjustment && goodService.adjustment != null) {
          taxBase = taxBase + ( goodService.amount * goodService.quantity ) + this.calculateAdjustmentTotal(invoice);
        } else {
          taxBase = taxBase + goodService.amount * goodService.quantity;
        }
      }
    }

    return roundToTwoDigitDecimal(taxBase);
  }

  public static calculateSocialContribution(
    invoice: InvoiceModel,
    user: UserModel,
    useSocialContributionAmountAlreadyCalculated: boolean
  ): number {
    if (user.recourseValue === undefined || !invoice.socialContribution) {
      return 0;
    }

    if (useSocialContributionAmountAlreadyCalculated) {
      if (
        invoice.socialContributionAmount !== undefined &&
        invoice.socialContributionAmount !== null
      ) {
        return invoice.socialContributionAmount;
      }

      return 0;
    }

    const stampAmount = CommonInvoiceService.calculateStamp(invoice);
    const taxBase = CommonInvoiceService.calculateTaxBase(invoice, true);

    let recourseValue: number | undefined = 0;
    if (invoice.fund !== undefined) {
      recourseValue = fundsMap.get(invoice.fund)?.recourseValue;
    }

    let socialContribution = 0;
    if (new Date(invoice.date).getFullYear() <= 2022) {
      socialContribution = (taxBase * user.recourseValue) / 100;
    } else if (invoice.fund !== undefined && recourseValue !== undefined) {
      socialContribution = ((taxBase + stampAmount) * recourseValue) / 100;
    }

    return roundToTwoDigitDecimal(socialContribution);
  }

  public static calculateWithholding(
    invoice: InvoiceModel,
    useWithholdingAlreadyCalculated: boolean
  ): number {
    if (!invoice.withholding) {
      return 0;
    }

    if (useWithholdingAlreadyCalculated) {
      if (
        invoice.withholding !== undefined &&
        invoice.withholding.amount !== undefined
      ) {
        return invoice.withholding.amount;
      }
      return 0;
    }

    if (invoice.withholding.rate === 0 || invoice.withholding.taxRate === 0) {
      return 0;
    }

    let rate = invoice.withholding.rate ? invoice.withholding.rate / 100 : 0;
    let taxRate = invoice.withholding.taxRate
      ? invoice.withholding.taxRate / 100
      : 0;
    let taxBase = CommonInvoiceService.calculateTaxBase(invoice, true);

    taxBase = invoice.stamp ? this.stampAmount + taxBase : taxBase;

    return roundToTwoDigitDecimal(taxRate * taxBase * rate);
  }

  public static calculateReimbursementsOfExpenses(
    invoice: InvoiceModel
  ): number {
    let reimbursementOfExpenseAmount = 0;

    if (
      invoice.reimbursementsOfExpensesAmount !== null &&
      invoice.reimbursementsOfExpensesAmount !== undefined &&
      invoice.reimbursementsOfExpensesAmount > 0
    ) {
      reimbursementOfExpenseAmount = invoice.reimbursementsOfExpensesAmount;
    }

    return roundToTwoDigitDecimal(reimbursementOfExpenseAmount);
  }

  public static calculateTaxes(invoice: InvoiceModel): number {
    const summary = CommonInvoiceService.getSummary(invoice);

    return summary.reduce((x, y) => x + Number(y.tax), 0);
  }

  public static getSummary(invoice: InvoiceModel): InvoiceSummaryModel[] {
    const summary: InvoiceSummaryModel[] = [];

    const goodServicesGroupedByVatNature: Map<VatNature, GoodServicesModel[]> =
      new Map<VatNature, GoodServicesModel[]>();

    if (invoice.goodServices !== undefined && invoice.goodServices !== null) {
      for (let goodService of invoice.goodServices) {
        if (
          goodService.vatNature === undefined ||
          goodService.vatNature === null
        ) {
          continue;
        }

        let existingGoodService = goodServicesGroupedByVatNature.get(
          goodService.vatNature
        );
        if (!existingGoodService) {
          existingGoodService = [];
        }

        goodServicesGroupedByVatNature.set(goodService.vatNature, [
          ...existingGoodService,
          goodService,
        ]);
      }
    }

    goodServicesGroupedByVatNature.forEach((value, key) => {
      const taxBase = value.reduce(
        (x, y) => x + Number(y.amount) * Number(y.quantity),
        0
      );

      const tax =
        taxBase * (Number(CommonInvoiceService.vatRateMap.get(key)) / 100);

      summary.push({
        key: uiidv4(),
        tax: tax,
        vatRate: CommonInvoiceService.vatRateMap.get(key),
      });
    });

    return summary;
  }

  public static getLastPaymentDate(invoice: InvoiceModel): Date | undefined {
    const payments = invoice.payments;

    if (payments === undefined || payments === null || payments.length <= 0) {
      return;
    }

    return payments[payments.length - 1].receiptDate;
  }

  public static getPdfName(
    invoiceSign: InvoiceSign,
    invoiceType?: InvoiceType,
    invoiceNumber?: string,
    invoiceCompanyName?: string
  ): string {
    const prefix = CommonInvoiceService.pdfNameMap.get(
      JSON.stringify({
        sign: invoiceSign,
        type: invoiceType,
      })
    );

    return (
      String(prefix) +
      `${invoiceNumber ? invoiceNumber + '-' : ''}` +
      invoiceCompanyName
    );
  }

  public static sumReports(
    customerInvoicesReports: InvoiceReportModel,
    stsInvoicesReports: StsInvoiceReportModel
  ): InvoiceReportModel {
    return {
      totalInvoice:
        customerInvoicesReports.totalInvoice + stsInvoicesReports.total,
      toPayInvoice: customerInvoicesReports.toPayInvoice,
      paidInvoice:
        customerInvoicesReports.paidInvoice + stsInvoicesReports.total,
      expiredInvoice: customerInvoicesReports.expiredInvoice,
    };
  }

  public async save(
    invoice: InvoiceModel,
    fileList: File[],
    reimbursementsOfExpenses: ReimbursementOfExpenseModel[]
  ): Promise<string | undefined> {
    invoice.documents = await this.saveFiles(fileList);
    invoice.reimbursementsOfExpenses = await this.saveReimbursementsOfExpense(reimbursementsOfExpenses);
    if (invoice.customNumeration!==undefined && !invoice.customNumeration) {
      invoice.number = undefined
    }

    return this.http
      .sendAndReceive({
        method: 'post',
        url: this.url,
        body: JSON.stringify(invoice),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      })
      .then((res) => res.json())
      .then((data: InvoiceModel) => data.id);
  }

  public async edit(
    invoice: InvoiceModel,
    fileList: File[],
    reimbursementsOfExpenses: ReimbursementOfExpenseModel[]
  ): Promise<void> {
    let documentsToDelete: string[] = [];
    let reimbursementsOfExpensesToDelete: ReimbursementOfExpenseModel[] = [];

    if (invoice.documents !== undefined && invoice.documents !== null) {
      documentsToDelete = [...invoice.documents];
    }
    if (
      invoice.reimbursementsOfExpenses !== undefined &&
      invoice.reimbursementsOfExpenses !== null
    ) {
      reimbursementsOfExpensesToDelete = [...invoice.reimbursementsOfExpenses];
    }

    invoice.documents = await this.saveFiles(fileList);
    invoice.reimbursementsOfExpenses = await this.saveReimbursementsOfExpense(
      reimbursementsOfExpenses
    );

    return this.http
      .sendAndReceive({
        method: 'put',
        url: this.url + '/' + invoice.id,
        body: JSON.stringify(invoice),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      })
      .then(() => {
        this.delFiles(documentsToDelete);
        this.delReimbursementsOfExpense(reimbursementsOfExpensesToDelete);
      });
  }

  public del(
    id: string | undefined,
    documents?: string[] | null,
    reimbursementsOfExpenses?: ReimbursementOfExpenseModel[] | null
  ): Promise<void> {
    return this.http
      .sendAndReceive({
        method: 'delete',
        url: this.url + '/' + id,
      })
      .then(() => {
        if (documents !== undefined && documents !== null) {
          this.delFiles(documents);
        }
        if (
          reimbursementsOfExpenses !== undefined &&
          reimbursementsOfExpenses !== null
        ) {
          this.delReimbursementsOfExpense(reimbursementsOfExpenses);
        }
      });
  }

  public changeStatus(
    id: string,
    status: ChangeInvoiceStatusModel
  ): Promise<void> {
    return this.http
      .sendAndReceive({
        method: 'put',
        url: this.url + '/' + id + '/status',
        body: JSON.stringify(status),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      })
      .then(() => undefined);
  }

  public getPdf(id: string): Promise<ArrayBuffer> {
    return this.http
      .sendAndReceive({
        method: 'get',
        url: this.url + '/' + id + '/pdf',
      })
      .then((data) => data.arrayBuffer());
  }

  public getReports(year: string): Promise<InvoiceReportModel> {
    return this.http
      .sendAndReceive({
        method: 'get',
        url: this.url + '/invoice-report?year=' + year,
      })
      .then((res) => res.json())
      .then((data: InvoiceReportModel) => data);
  }

  public getTotalRevenues(year: number): Promise<TotalRevenuesModel> {
    return this.http
      .sendAndReceive({
        method: 'get',
        url: this.url + '/total-revenues?year=' + year,
      })
      .then((res) => res.json())
      .then((data: TotalRevenuesModel) => data);
  }

  public deletePayment(invoiceId: string, paymentId: string): Promise<void> {
    return this.http
      .sendAndReceive({
        method: 'delete',
        url: this.url + '/' + invoiceId + '/payments/' + paymentId,
      })
      .then(() => undefined);
  }

  protected async getFiles(documents: string[]): Promise<File[]> {
    const fileList: File[] = [];

    for (let document of documents) {
      const object = await this.objectService.findById(document);
      const arrayBuffer = await this.objectService.findFile(document);

      fileList.push(toFile(arrayBuffer, object));
    }

    return fileList;
  }

  private async saveFiles(fileList: File[]): Promise<string[]> {
    const documents: string[] = [];

    for (let file of fileList) {
      await this.objectService
        .save({
          name: file.name,
          type: ObjectType.FISCAL_DOCUMENT,
          extension: getExtension(file),
          file: await toBase64(file),
        })
        .then((data) => {
          if (data.id) {
            documents.push(data.id);
          }
        });
    }

    return documents;
  }

  private async delFiles(documents: string[]): Promise<void> {
    for (let document of documents) {
      await this.objectService.del(document);
    }

    return Promise.resolve();
  }

  private async saveReimbursementsOfExpense(
    reimbursementsOfExpenses: ReimbursementOfExpenseModel[]
  ): Promise<ReimbursementOfExpenseModel[]> {
    let response: ReimbursementOfExpenseModel[] = [];

    for (const reimbursementOfExpenses of reimbursementsOfExpenses) {
      let documentId: string[] = [];

      if (reimbursementOfExpenses.file !== undefined) {
        documentId = await this.saveFiles([reimbursementOfExpenses.file]);
      }

      if (documentId.length > 0) {
        response.push({
          key: reimbursementOfExpenses.key,
          description: reimbursementOfExpenses.description,
          amount: reimbursementOfExpenses.amount,
          documentId: documentId[0],
        });
      } else {
        response.push({
          key: reimbursementOfExpenses.key,
          description: reimbursementOfExpenses.description,
          amount: reimbursementOfExpenses.amount,
        });
      }
    }

    return response;
  }

  private async delReimbursementsOfExpense(
    reimbursementsOfExpenses: ReimbursementOfExpenseModel[]
  ): Promise<void> {
    for (let reimbursementOfExpenses of reimbursementsOfExpenses) {
      if (reimbursementOfExpenses.documentId) {
        await this.delFiles([reimbursementOfExpenses.documentId]);
      }
    }

    return Promise.resolve();
  }

  public validate(
    invoice: InvoiceModel,
    invoiceSign: InvoiceSign,
    reimbursementOfExpenses?: ReimbursementOfExpenseModel[]
  ): boolean {
    let isValid = true;

    if (
      !this.validateContact(
        invoiceSign === 'auto' ? invoice.supplier : invoice.customer
      )
    ) {
      isValid = false;
    }
    if (!this.validateGoodServices(invoice.goodServices)) {
      isValid = false;
    }

    if (
      !this.validateGoodServicesValues(
        invoice.goodServices,
        invoiceSign,
        invoice.stsIsActive
      )
    ) {
      isValid = false;
    }

    if (
      invoiceSign !== 'auto' &&
      !this.validatePaymentMode(invoice.paymentMode)
    ) {
      isValid = false;
    }

    if (!this.validateDate(invoice.date)) {
      isValid = false;
    }

    if (
      invoiceSign !== 'occasionalJob' &&
      !this.validatePaymentExpiration(invoice.date, invoice.paymentExpiration)
    ) {
      isValid = false;
    }

    if (
      invoiceSign === 'customer' &&
      !this.validateReimbursementsOfExpenses(reimbursementOfExpenses)
    ) {
      isValid = false;
    }

    if (
      invoiceSign === 'proforma' &&
      !this.validateReimbursementsOfExpenses(reimbursementOfExpenses)
    ) {
      isValid = false;
    }

    if (invoiceSign === 'auto' && !this.validateDocumentType(invoice.type)) {
      isValid = false;
    }

    if (
      invoiceSign === 'customer' &&
      !this.validateDocumentType(invoice.type)
    ) {
      isValid = false;
    }

    if (
      invoiceSign === 'customer' &&
      !this.validateWithholdingRate(
        invoice.withholding?.active,
        invoice.withholding?.rate
      )
    ) {
      isValid = false;
    }

    if (
      invoiceSign === 'customer' &&
      !this.validateWithholdingTaxRate(
        invoice.withholding?.active,
        invoice.withholding?.taxRate
      )
    ) {
      isValid = false;
    }

    if (
      invoiceSign !== 'auto' &&
      invoiceSign !== 'supplier' &&
      invoice.socialContribution &&
      !this.validateEnasarco(invoice.enasarcoSocialContribution)
    ) {
      isValid = false;
    }

    if (
      (invoiceSign === 'sts' ||
        (invoiceSign === 'proforma' && invoice.stsIsActive)) &&
      invoice.trackedPayment &&
      !this.validateTrackedPayment(invoice.trackedPayment)
    ) {
      isValid = false;
    }

    if (
      (invoiceSign === 'sts' ||
        (invoiceSign === 'proforma' && invoice.stsIsActive)) &&
      !this.validateSendData(invoice.customerDataCommunication)
    ) {
      isValid = false;
    }

    if (
      (invoiceSign === 'occasionalJob' ||
        invoiceSign === 'sts' ||
        (invoiceSign === 'proforma' && invoice.stsIsActive)) &&
      !this.validateStampId(invoice.stampId, invoice.goodServices)
    ) {
      isValid = false;
    }

    if (
      invoiceSign === 'occasionalJob' &&
      !this.validateStartEndDate(invoice.startDate, invoice.endDate)
    ) {
      isValid = false;
    }

    if (
      invoiceSign === 'customer' &&
      invoice.customNumeration &&
      !this.validateCustomNumeration(invoice.number)
    ) {
      isValid = false;
    }

    return isValid;
  }

  public validateEnasarco(enasarcoSocialContribution?: number): boolean {
    this.errorSetters?.setErrorEnasarco &&
      this.errorSetters?.setErrorEnasarco(false);

    if (
      enasarcoSocialContribution !== undefined &&
      isNaN(enasarcoSocialContribution)
    ) {
      this.errorSetters?.setErrorEnasarco &&
        this.errorSetters?.setErrorEnasarco(true);
      return false;
    }

    return true;
  }

  public validateContact(contact?: ContactModel): boolean {
    this.errorSetters?.setErrorCustomer(false);

    if (contact === undefined) {
      this.errorSetters?.setErrorCustomer(true);
      return false;
    }

    return true;
  }

  public validatePaymentMode(paymentMode?: string): boolean {
    this.errorSetters?.setErrorPaymentMode &&
      this.errorSetters?.setErrorPaymentMode(false);

    if (paymentMode === undefined || paymentMode === '') {
      this.errorSetters?.setErrorPaymentMode &&
        this.errorSetters?.setErrorPaymentMode(true);
      return false;
    }

    return true;
  }

  public validateDate(date?: Date): boolean {
    this.errorSetters?.setErrorDate && this.errorSetters?.setErrorDate(false);

    if (!date) {
      this.errorSetters?.setErrorDateMessage &&
        this.errorSetters?.setErrorDateMessage(
          'Inserire una data di emissione'
        );
      this.errorSetters?.setErrorDate && this.errorSetters?.setErrorDate(true);
      return false;
    }

    return true;
  }

  public validateGoodServices(
    goodServices?: GoodServicesModel[] | null
  ): boolean {
    this.errorSetters?.setErrorGoodServices(false);

    if (
      goodServices === undefined ||
      goodServices === null ||
      goodServices.length <= 0
    ) {
      this.errorSetters?.setErrorGoodServices(true);
      return false;
    }

    return true;
  }

  public validateGoodServicesValues(
    goodServices?: GoodServicesModel[] | null,
    invoiceSign?: InvoiceSign,
    stsIsActive?: boolean
  ): boolean {
    this.errorSetters?.setErrorGoodServicesInput(false);

    if (goodServices === undefined || goodServices === null) {
      return false;
    }

    for (let goodService of goodServices) {
      if (
        goodService.description === '' ||
        goodService.description === undefined
      ) {
        this.errorSetters?.setErrorGoodServicesInput(true);
        return false;
      }

      if (goodService.quantity === undefined || goodService.quantity <= 0) {
        this.errorSetters?.setErrorGoodServicesInput(true);
        return false;
      }

      if (
        goodService.amount === undefined ||
        (goodService.amount === undefined && invoiceSign !== 'auto') ||
        (invoiceSign === 'occasionalJob' && goodService.amount === 0)
      ) {
        this.errorSetters?.setErrorGoodServicesInput(true);
        return false;
      }

      if (invoiceSign === 'auto' && goodService.vatNature === undefined) {
        this.errorSetters?.setErrorGoodServicesInput(true);
        return false;
      }

      if (
        (invoiceSign === 'sts' ||
          (invoiceSign === 'proforma' && stsIsActive)) &&
        (goodService.expenseType === null ||
          goodService.expenseType === undefined)
      ) {
        this.errorSetters?.setErrorGoodServicesInput(true);
        return false;
      }
    }

    return true;
  }

  public validateExpenseType(expenseType?: ExpenseType): boolean {
    this.errorSetters?.setErrorExpenseType &&
      this.errorSetters?.setErrorExpenseType(false);

    if (expenseType === undefined) {
      this.errorSetters?.setErrorExpenseType &&
        this.errorSetters?.setErrorExpenseType(true);
      return false;
    }

    return true;
  }

  public validateTrackedPayment(trackedPayment?: boolean): boolean {
    this.errorSetters?.setErrorTrackedPayment &&
      this.errorSetters?.setErrorTrackedPayment(false);

    if (trackedPayment === undefined) {
      this.errorSetters?.setErrorTrackedPayment &&
        this.errorSetters?.setErrorTrackedPayment(true);
      return false;
    }

    return true;
  }

  public validateSendData(sendData?: boolean): boolean {
    this.errorSetters?.setErrorSendData &&
      this.errorSetters?.setErrorSendData(false);

    if (sendData === undefined) {
      this.errorSetters?.setErrorSendData &&
        this.errorSetters?.setErrorSendData(true);
      return false;
    }

    return true;
  }

  public validatePaymentExpiration(
    date: Date,
    paymentExpiration?: Date
  ): boolean {
    this.errorSetters?.setErrorPaymentExpiration &&
      this.errorSetters?.setErrorPaymentExpiration(false);

    if (
      !paymentExpiration ||
      isAfter(removeTime(date), removeTime(paymentExpiration))
    ) {
      this.errorSetters?.setErrorPaymentExpiration &&
        this.errorSetters?.setErrorPaymentExpiration(true);
      return false;
    }

    return true;
  }

  public validateReimbursementsOfExpenses(
    reimbursementsOfExpenses?: ReimbursementOfExpenseModel[]
  ): boolean {
    this.errorSetters?.setErrorReimbursementsOfExpenses &&
      this.errorSetters?.setErrorReimbursementsOfExpenses(false);

    if (reimbursementsOfExpenses === undefined) {
      return true;
    }

    for (let reimbursementOfExpenses of reimbursementsOfExpenses) {
      if (
        reimbursementOfExpenses.amount === undefined ||
        reimbursementOfExpenses.amount <= 0 ||
        (!reimbursementOfExpenses.description &&
          reimbursementOfExpenses.description === '')
      ) {
        this.errorSetters?.setErrorReimbursementsOfExpenses &&
          this.errorSetters?.setErrorReimbursementsOfExpenses(true);
        return false;
      }
    }

    return true;
  }

  public validateDocumentType(documentType?: InvoiceType): boolean {
    this.errorSetters?.setErrorDocumentType &&
      this.errorSetters?.setErrorDocumentType(false);

    if (documentType === undefined) {
      this.errorSetters?.setErrorDocumentType &&
        this.errorSetters?.setErrorDocumentType(true);
      return false;
    }

    return true;
  }

  public validateCustomNumeration(number?: string): boolean {
    this.errorSetters?.setErrorCustomNumeration &&
      this.errorSetters?.setErrorCustomNumeration(false);

    if (
      number === undefined ||
      (number && !customNumerationRegex.test(number))
    ) {
      this.errorSetters?.setErrorCustomNumeration &&
        this.errorSetters?.setErrorCustomNumeration(true);
      return false;
    }

    return true;
  }

  public validateWithholdingRate(active?: boolean, rate?: number): boolean {
    this.errorSetters?.setErrorWithholdingRate &&
      this.errorSetters?.setErrorWithholdingRate(false);

    if (active && rate === undefined) {
      this.errorSetters?.setErrorWithholdingRate &&
        this.errorSetters?.setErrorWithholdingRate(true);
      return false;
    }

    return true;
  }

  public validateWithholdingTaxRate(
    active?: boolean,
    taxRate?: number
  ): boolean {
    this.errorSetters?.setErrorWithholdingTaxRate &&
      this.errorSetters?.setErrorWithholdingTaxRate(false);

    if (active && taxRate === undefined) {
      this.errorSetters?.setErrorWithholdingTaxRate &&
        this.errorSetters?.setErrorWithholdingTaxRate(true);
      return false;
    }

    return true;
  }

  public validateStampId(
    stamptId?: string,
    goodServices?: GoodServicesModel[] | null
  ): boolean {
    this.errorSetters?.setErrorStampId &&
      this.errorSetters?.setErrorStampId(false);

    let totalAmount = 0;
    goodServices?.map(
      (goodService) =>
        (totalAmount = totalAmount + goodService.amount * goodService.quantity)
    );

    if (totalAmount > config.stampIdTheshold && !stamptId) {
      this.errorSetters?.setErrorStampId &&
        this.errorSetters?.setErrorStampId(true);
      return false;
    }

    if (totalAmount < config.stampIdTheshold && stamptId) {
      this.errorSetters?.setErrorStampId &&
        this.errorSetters?.setErrorStampId(true);
      return false;
    }

    if (stamptId && stamptId.length < 8) {
      this.errorSetters?.setErrorStampId &&
        this.errorSetters?.setErrorStampId(true);
      return false;
    }

    return true;
  }

  public validateStartEndDate(startDate?: Date, endDate?: Date): boolean {
    this.errorSetters?.setErrorStartEndDate &&
      this.errorSetters?.setErrorStartEndDate(false);

    if (startDate && endDate) {
      if (isAfter(removeTime(startDate), removeTime(endDate))) {
        this.errorSetters?.setErrorStartEndDate &&
          this.errorSetters?.setErrorStartEndDate(true);
        return false;
      }
    }

    return true;
  }
}

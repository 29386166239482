import { useEffect, useState } from 'react';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { useNavigate } from 'react-router-dom';
import { formatNumberIT } from '../../utils/number';
import { QuickfiscoSwitchInput } from '../quickfiscoSwitchInput/quickfiscoSwitchInput';
import { useAppSelector } from '../../redux/hooks';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { AtecoSelect } from '../atecoSelect/atecoSelect';
import { CategoryType } from '../../models/userModel';
import { TaxationSelect } from '../taxationSelect/taxationSelect';
import { ContributionsPaidFundSelect } from '../contributionsPaidFundSelect/contributionsPaidFundSelect';
import { AmountInvoicedInfo } from '../amountInvoicedInfo/amountInvoicedInfo';
import { ContributionsPaidFundInfo } from '../contributionsPaidFundInfo/contributionsPaidFundInfo';
import { FundAmountInfo } from '../fundAmountInfo/fundAmountInfo';
import { NumericFormat } from 'react-number-format';
import uri from '../../routers/quickfiscoUri.json';
import lang from './feesCalculator.json';
import './feesCalculator.css';

interface Props {
  isProfileCompleted: boolean;
}

export function FeesCalculator(props: Props) {
  const { isProfileCompleted } = props;

  const navigate = useNavigate();

  const userState = useAppSelector((state) => state.user);
  const atecoState = useAppSelector((state) => state.ateco);
  const user = userState.user;
  const atecosArray = atecoState.atecos;

  const [isGrossFeeButton, setIsGrossFeeButton] = useState<boolean>(false);
  const [selectedAteco, setSelectedAteco] = useState<string | undefined>(
    user.atecos && user.atecos[0] ? user.atecos[0] : undefined
  );
  const [amount, setAmount] = useState<number | undefined>(undefined);
  const [amountInvoiced, setAmountInvoiced] = useState<number>(0);
  const [boxPercentageGain, setBoxPercentageGain] = useState<number>(0);

  const [haveIncome, setHaveIncome] = useState<boolean>(false);
  const [inpsCompensation, setInpsCompensation] = useState<boolean>(false);

  const [contributionsPaidFund, setContributionsPaidFund] =
    useState<number>(0.05);
  const [taxation, setTaxation] = useState<number>(
    user.taxation === 5 ? 0.05 : 0.15
  );

  const [contributionReduction, setContributionReduction] =
    useState<boolean>(false);
  const [contributionExemption, setContributionExemption] =
    useState<boolean>(false);

  let totalNoFundAmountInvoice: number;
  const haveIncomeCoefficient: number = haveIncome ? 0.24 : 0.2607;
  const atecoObjCoefficient = atecosArray.find(
    (ateco) => ateco.code === selectedAteco
  )?.coefficientOfProfitability;

  useEffect(() => {
    setAmountInvoiced(0);
  }, [
    taxation,
    haveIncomeCoefficient,
    atecoObjCoefficient,
    amount,
    haveIncome,
    inpsCompensation,
    contributionsPaidFund,
    contributionReduction,
    contributionExemption,
  ]);

  useEffect(() => {
    if (!amount) setInpsCompensation(false);
  }, [amount]);

  const noFundAmountInvoice = () => {
    if (atecoObjCoefficient && amount) {
      if (isGrossFeeButton) {
        totalNoFundAmountInvoice =
          amount /
          (1 -
            atecoObjCoefficient * haveIncomeCoefficient -
            atecoObjCoefficient * taxation);
        setAmountInvoiced(totalNoFundAmountInvoice);
        setBoxPercentageGain(amount / totalNoFundAmountInvoice);
      } else {
        totalNoFundAmountInvoice =
          amount -
          amount * atecoObjCoefficient * haveIncomeCoefficient -
          amount * atecoObjCoefficient * taxation;
        setAmountInvoiced(totalNoFundAmountInvoice);
        setBoxPercentageGain(totalNoFundAmountInvoice / amount);
      }
    }
  };

  const fundAmountInvoice = () => {
    if (atecoObjCoefficient && amount) {
      if (isGrossFeeButton) {
        totalNoFundAmountInvoice =
          amount /
          (1 -
            atecoObjCoefficient * contributionsPaidFund -
            atecoObjCoefficient * taxation);
        setAmountInvoiced(totalNoFundAmountInvoice);
        setBoxPercentageGain(amount / totalNoFundAmountInvoice);
      } else {
        totalNoFundAmountInvoice =
          amount -
          amount * atecoObjCoefficient * contributionsPaidFund -
          amount * atecoObjCoefficient * taxation;
        setAmountInvoiced(totalNoFundAmountInvoice);
        setBoxPercentageGain(totalNoFundAmountInvoice / amount);
      }
    }
  };

  const traderArtisanAmountInvoice = () => {
    if (atecoObjCoefficient && amount) {
      if (isGrossFeeButton) {
        if (user.category === CategoryType.ARTISAN) {
          if (contributionReduction && !contributionExemption) {
            totalNoFundAmountInvoice =
              amount /
              (1 -
                atecoObjCoefficient * 0.156 -
                (atecoObjCoefficient - atecoObjCoefficient * 0.156) * taxation);
          } else if (!contributionReduction && !contributionExemption) {
            totalNoFundAmountInvoice =
              amount /
              (1 -
                atecoObjCoefficient * 0.24 -
                (atecoObjCoefficient - atecoObjCoefficient * 0.24) * taxation);
          } else if (!contributionReduction && contributionExemption) {
            totalNoFundAmountInvoice =
              amount / (1 - atecoObjCoefficient * taxation);
          }
          setAmountInvoiced(totalNoFundAmountInvoice);
          setBoxPercentageGain(amount / totalNoFundAmountInvoice);
        } else if (user.category === CategoryType.TRADER) {
          if (contributionReduction && !contributionExemption) {
            totalNoFundAmountInvoice =
              amount /
              (1 -
                atecoObjCoefficient * 0.156 -
                (atecoObjCoefficient - atecoObjCoefficient * 0.156) * taxation);
          } else if (!contributionReduction && !contributionExemption) {
            totalNoFundAmountInvoice =
              amount /
              (1 -
                atecoObjCoefficient * 0.2448 -
                (atecoObjCoefficient - atecoObjCoefficient * 0.2448) *
                  taxation);
          } else if (!contributionReduction && contributionExemption) {
            totalNoFundAmountInvoice =
              amount / (1 - atecoObjCoefficient * taxation);
          }
          setAmountInvoiced(totalNoFundAmountInvoice);
          setBoxPercentageGain(amount / totalNoFundAmountInvoice);
        }
      } else {
        if (user.category === CategoryType.ARTISAN) {
          if (contributionReduction && !contributionExemption) {
            totalNoFundAmountInvoice =
              amount -
              amount * atecoObjCoefficient * 0.156 -
              (amount * atecoObjCoefficient -
                amount * atecoObjCoefficient * 0.156) *
                taxation;
          } else if (!contributionReduction && !contributionExemption) {
            totalNoFundAmountInvoice =
              amount -
              amount * atecoObjCoefficient * 0.24 -
              (amount * atecoObjCoefficient -
                amount * atecoObjCoefficient * 0.24) *
                taxation;
          } else if (!contributionReduction && contributionExemption) {
            totalNoFundAmountInvoice =
              amount - amount * atecoObjCoefficient * taxation;
          }
        } else if (user.category === CategoryType.TRADER) {
          if (contributionReduction && !contributionExemption) {
            totalNoFundAmountInvoice =
              amount -
              amount * atecoObjCoefficient * 0.1591 -
              (amount * atecoObjCoefficient -
                amount * atecoObjCoefficient * 0.1591) *
                taxation;
          } else if (!contributionReduction && !contributionExemption) {
            totalNoFundAmountInvoice =
              amount -
              amount * atecoObjCoefficient * 0.2448 -
              (amount * atecoObjCoefficient -
                amount * atecoObjCoefficient * 0.2448) *
                taxation;
          } else if (!contributionReduction && contributionExemption) {
            totalNoFundAmountInvoice =
              amount - amount * atecoObjCoefficient * taxation;
          }
        }
        setAmountInvoiced(totalNoFundAmountInvoice);
        setBoxPercentageGain(totalNoFundAmountInvoice / amount);
      }
    }
  };

  const notFund: React.ReactNode = (
    <div className="row">
      <div className="col-12">
        <NumericFormat
          key={'input-fees-calculator-not-fund-amount'}
          id={'fees-calculator-not-fund-amount'}
          suffix="€"
          value={amount}
          decimalSeparator=","
          decimalScale={2}
          customInput={QuickfiscoInput}
          onValueChange={(e) => {
            setAmount(e.floatValue);
          }}
          styleType={'medium'}
          label={!isGrossFeeButton ? lang.addGrossAmount : lang.addNetAmount}
          placeholder={lang.addAmountPlaceholder}
          fixedDecimalScale
          required={true}
        />
      </div>
      {!isGrossFeeButton && (
        <div className="col-12 mb-2">
          <QuickfiscoSwitchInput
            id={'fees-calculator-not-fund-contribution-reduction'}
            label={lang.notFund.inpsCompensation}
            onChange={(checked) => {
              setInpsCompensation(checked);
              if (amount) {
                if (checked) {
                  setAmount(Number((amount * 1.04).toFixed(2)));
                } else {
                  setAmount(Number((amount / 1.04).toFixed(2)));
                }
              }
            }}
            checked={inpsCompensation}
            required={true}
          />
        </div>
      )}
      <div className="col-12 mt-2">
        <AtecoSelect
          id={'fees-calculator-not-fund-ateco-select'}
          onChange={(_ateco) => _ateco && setSelectedAteco(_ateco)}
          value={selectedAteco}
          type={'own'}
          requiredField={true}
          disabled={user.atecos && user.atecos.length > 1 ? false : true}
          isGetAtecos={true}
        />
      </div>
      <div className="col-12 mt-2">
        <TaxationSelect
          id={'fees-calculator-not-fund-taxation'}
          onChange={(taxation) => setTaxation(taxation)}
          value={taxation}
          requiredField={true}
        />
      </div>
      <div className="col-12 mt-4">
        <QuickfiscoSwitchInput
          id={'fees-calculator-not-fund-contribution-reduction'}
          label={lang.notFund.haveIncome}
          onChange={(checked) => setHaveIncome(checked)}
          checked={haveIncome}
          required={true}
        />
      </div>
      <div className="col-12 mt-4">
        <QuickfiscoButton
          id="fees-calculator-not-fund-calculation-button"
          label={
            !isGrossFeeButton
              ? lang.calculationNetButton
              : lang.calculationGrossButton
          }
          type={amount ? 'secondary' : 'disabled'}
          onClick={() => noFundAmountInvoice()}
        />
      </div>
    </div>
  );

  const fund: React.ReactNode = (
    <div className="row">
      <div className={`${isGrossFeeButton ? 'col-10' : 'col-12'}`}>
        <NumericFormat
          key={'input-fees-calculator-fund-amount'}
          id={'fees-calculator-fund-amount'}
          suffix="€"
          value={amount}
          decimalSeparator=","
          decimalScale={2}
          customInput={QuickfiscoInput}
          onValueChange={(e) => {
            setAmount(e.floatValue);
          }}
          styleType={'medium'}
          label={!isGrossFeeButton ? lang.addGrossAmount : lang.addNetAmount}
          placeholder={lang.addAmountPlaceholder}
          fixedDecimalScale
          required={true}
        />
      </div>
      {isGrossFeeButton && (
        <div className="col-2 d-flex align-items-center justify-content-center">
          <FundAmountInfo />
        </div>
      )}
      <div className="col-12 mt-2">
        <AtecoSelect
          id={'fees-calculator-fund-ateco-select'}
          onChange={(_ateco) => _ateco && setSelectedAteco(_ateco)}
          value={selectedAteco}
          type={'own'}
          requiredField={true}
          disabled={user.atecos && user.atecos.length > 1 ? false : true}
          isGetAtecos={true}
        />
      </div>
      <div className="col-12 mt-2">
        <TaxationSelect
          id={'fees-calculator-fund-taxation'}
          onChange={(taxation) => setTaxation(taxation)}
          value={taxation}
          requiredField={true}
        />
      </div>
      <div className="col-10 mt-3">
        <ContributionsPaidFundSelect
          id={'fees-calculator-fund-contributions-paid-fund-select'}
          onChange={(taxation) => setContributionsPaidFund(taxation)}
          value={contributionsPaidFund}
          requiredField={true}
        />
      </div>
      <div className="col-2 mt-3 d-flex align-items-center justify-content-center">
        <ContributionsPaidFundInfo />
      </div>
      <div className="col-12 mt-4">
        <QuickfiscoButton
          id="fees-calculator-fund-calculation-button"
          label={
            !isGrossFeeButton
              ? lang.calculationNetButton
              : lang.calculationGrossButton
          }
          type={amount ? 'secondary' : 'disabled'}
          onClick={() => fundAmountInvoice()}
        />
      </div>
    </div>
  );

  const traderArtisan: React.ReactNode = (
    <div className="row">
      <div className="col-12">
        <NumericFormat
          key={'input-fees-calculator-trader-artisan-amount'}
          id={'fees-calculator-trader-artisan-amount'}
          suffix="€"
          value={amount}
          decimalSeparator=","
          decimalScale={2}
          customInput={QuickfiscoInput}
          onValueChange={(e) => {
            setAmount(e.floatValue);
          }}
          styleType={'medium'}
          label={!isGrossFeeButton ? lang.addGrossAmount : lang.addNetAmount}
          placeholder={lang.addAmountPlaceholder}
          fixedDecimalScale
          required={true}
        />
      </div>
      <div className="col-12 mt-2">
        <AtecoSelect
          id={'fees-calculator-trader-artisan-ateco-select'}
          onChange={(_ateco) => _ateco && setSelectedAteco(_ateco)}
          value={selectedAteco}
          type={'own'}
          requiredField={true}
          disabled={user.atecos && user.atecos.length > 1 ? false : true}
          isGetAtecos={true}
        />
      </div>
      <div className="col-12 mt-2">
        <TaxationSelect
          id={'fees-calculator-trader-artisan-taxation'}
          onChange={(taxation) => setTaxation(taxation)}
          value={taxation}
          requiredField={true}
        />
      </div>
      <div className="col-12 mt-4">
        <QuickfiscoSwitchInput
          id={'fees-calculator-trader-artisan-contribution-reduction'}
          label={lang.traderArtisan.contributionReduction}
          onChange={(checked) => setContributionReduction(checked)}
          checked={contributionReduction}
          required={true}
          disabled={contributionExemption}
        />
      </div>
      <div className="col-12 mt-2">
        <QuickfiscoSwitchInput
          id={'fees-calculator-trader-artisan-contribution-reduction'}
          label={lang.traderArtisan.contributionExemption}
          onChange={(checked) => setContributionExemption(checked)}
          checked={contributionExemption}
          required={true}
          disabled={contributionReduction}
        />
      </div>
      <div className="col-12 mt-4">
        <QuickfiscoButton
          id="fees-calculator-trader-artisan-calculation-button"
          label={
            !isGrossFeeButton
              ? lang.calculationNetButton
              : lang.calculationGrossButton
          }
          type={amount ? 'secondary' : 'disabled'}
          onClick={() => traderArtisanAmountInvoice()}
        />
      </div>
    </div>
  );

  let feesCalculatorBox: React.ReactNode;

  switch (user.category) {
    case CategoryType.NOT_FUND:
      feesCalculatorBox = notFund;
      break;
    case CategoryType.FUND:
      feesCalculatorBox = fund;
      break;
    default:
      feesCalculatorBox = traderArtisan;
  }

  return (
    <div className="fees-calculator-container box-shadow p-4">
      <div className="row mt-1 no-gutters" style={{ height: '45px' }}>
        <div className="col-5 no-gutters fees-calculator-title">
          {lang.title}
        </div>
        {isProfileCompleted && (
          <div className="col-7">
            <div className="row d-flex align-items-center">
              <div className="col-12">
                <div className={'row trend-graphs-buttons-container'}>
                  <div
                    className={`col-6 ${
                      !isGrossFeeButton
                        ? 'trend-graphs-buttons-selected'
                        : 'trend-graphs-buttons'
                    }`}
                    onClick={() => {
                      setIsGrossFeeButton(false);
                      setAmountInvoiced(0);
                    }}
                  >
                    <span>{lang.netFeeButton}</span>
                  </div>
                  <div
                    className={`col-6 ${
                      isGrossFeeButton
                        ? 'trend-graphs-buttons-selected'
                        : 'trend-graphs-buttons'
                    }`}
                    onClick={() => {
                      setIsGrossFeeButton(true);
                      setAmountInvoiced(0);
                    }}
                  >
                    <span>{lang.grossFeeButton}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {isProfileCompleted && user.category && user.taxation ? (
        <div className="row my-4">
          <div className="col-6 d-flex flex-column justify-content-center">
            {feesCalculatorBox}
          </div>
          <div className="col-6">
            <div className="fees-calculator-box px-3 py-4">
              <div className={'fees-calculator-box-title'}>
                <div className="d-flex align-items-center justify-content-center">
                  {!isGrossFeeButton ? lang.boxNetTitle : lang.boxGrossTitle}
                  <AmountInvoicedInfo />
                </div>
              </div>
              <div className={'fees-calculator-box-amount-invoices my-4'}>
                {amountInvoiced === 0 ? '0,00' : formatNumberIT(amountInvoiced)}{' '}
                €
              </div>
              <div className={'fees-calculator-box-perc-number'}>
                {lang.boxPercNumber}{' '}
                <span>{(boxPercentageGain * 100).toFixed(2) + '%'}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="row my-4">
          <div className="col-12 mt-4">
            <div className={'row'}>
              <div className={'col-12 d-flex justify-content-center'}>
                <QuickfiscoIcon name={'alert-yellow.svg'} />
              </div>
            </div>
            <div className={'row mt-4 text-center'}>
              <div
                className={'col-12 fees-calculator-no-completed-profile-title'}
              >
                {lang.noCompletedProfileTitle}
              </div>
              <div className={'col-12 mt-3'}>{lang.noCompletedProfileText}</div>
            </div>
            <div className="row mt-4">
              <div className="col-6 offset-3 mb-4 fees-calculator-title">
                <QuickfiscoButton
                  id={'fees-calculator-go-to-button'}
                  label={lang.noCompletedProfileButton}
                  type={'secondary'}
                  onClick={() => navigate(uri.Profile)}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="row fees-calculator-tax-info pt-1">
        <div className="col-10 offset-1">{lang.taxInfo}</div>
      </div>
      <div className="row mt-2">
        <div className="col-6 offset-3 mt-1 fees-calculator-title">
          <QuickfiscoButton
            id={'fees-calculator-go-to-button'}
            label={lang.goToButton}
            type={'primary'}
            onClick={() => navigate(uri.TaxCalculation)}
          />
        </div>
      </div>
    </div>
  );
}

import { useEffect, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import {
  GoodServiceOperation,
  GoodServiceUnit,
  GoodServicesModel,
} from '../../models/invoiceModel';
import { GoodServiceOperationSelect } from '../goodServiceOperationSelect/goodServiceOperationSelect';
import { GoodServiceSelect } from '../goodServiceSelect/goodServiceSelect';
import { GoodServiceUnitSelect } from '../goodServiceUnitSelect/goodServiceUnitSelect';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import './adjustmentModal.css';
import lang from './adjustmentModal.json';

interface Props {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  goodServices: GoodServicesModel[];
  setGoodServices: (goodServices: GoodServicesModel[]) => void;
}

export function AdjustmentModal(props: Props) {
  const { open } = props;
  const { setOpen } = props;
  const { goodServices } = props;
  const { setGoodServices } = props;

  const [index, setIndex] = useState<number | undefined>(undefined);
  const [operation, setOperation] = useState<GoodServiceOperation | undefined>(
    undefined
  );
  const [unit, setUnit] = useState<GoodServiceUnit | undefined>(undefined);
  const [amount, setAmount] = useState<number | undefined>(0);

  useEffect(() => {
    setIndex(undefined)
    setOperation(undefined)
    setUnit(undefined)
    setAmount(0)
  }, [open]);

  return (
    <div>
      <QuickfiscoModal
        isOpen={open}
        hide={() => setOpen(false)}
        modalClassName="adjustment-modal"
      >
        <div className="row text-center">
          <div className="col adjustment-modal-title">{lang.title}</div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="col-8">
            <GoodServiceSelect
              value={index}
              onChange={(value) => setIndex(value)}
            />
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="col-8 adjustment-modal-select">
            <GoodServiceOperationSelect
              value={operation}
              onChange={(value) => setOperation(value)}
            />
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="col-4 adjustment-modal-select">
            <GoodServiceUnitSelect
              value={unit === GoodServiceUnit.FIXED ? '€' : '%'}
              onChange={(value) => setUnit(value)}
            />
          </div>
          <div className="col-4 adjustment-modal-input">
            <NumericFormat
              id={`amount-adjustment`}
              customInput={QuickfiscoInput}
              styleType={'default-right'}
              type="text"
              placeholder={'0'}
              onValueChange={(e) => {
                setAmount(e.floatValue);
              }}
              value={amount}
              suffix={unit === GoodServiceUnit.FIXED ? '€' : '%'}
              required
            />
          </div>
        </div>
        <div className="row  justify-content-center align-items-center">
          <div className="col-6 adjustment-info-text adjustment-info-bg-right d-flex align-items-center">
            <span>{lang.amount}</span>
          </div>
          <div className="col-4 adjustment-info-amount adjustment-info-bg-left d-flex align-items-center justify-content-end">
            <span>
              {index && goodServices && unit && amount && operation
                ? `€ ${calculateAdjustmentAmount(
                    goodServices[index].amount,
                    goodServices[index].quantity,
                    unit,
                    amount,
                    operation,
                  )}`
                : `€ 0`}
            </span>
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="col-3 adjustment-info-button m-3">
            <QuickfiscoButton
              id={'invoice-adjustment-add-goodservice'}
              type={'primary'}
              label={lang.add}
              onClick={() => {
                if (index && amount && operation && unit) {
                  const newGoodServices = [...goodServices];

                  newGoodServices[index] = {
                    ...goodServices[index],
                    adjustment: { amount: amount, type: operation, unit: unit },
                  };

                  setGoodServices([...newGoodServices]);
                  setOpen(false);
                }
              }}
            />
          </div>
        </div>
      </QuickfiscoModal>
    </div>
  );
}

function calculateAdjustmentAmount(
  amount: number,
  quantity: number,
  unit: GoodServiceUnit,
  adjustment: number,
  operation: GoodServiceOperation
): number {
  switch (operation) {
    case GoodServiceOperation.SC:
      switch (unit) {
        case GoodServiceUnit.FIXED:
          return amount * quantity - adjustment * quantity;
        case GoodServiceUnit.PERCENTAGE:
          return amount * quantity - ((amount * adjustment) / 100) * quantity;
      }
      break;
    case GoodServiceOperation.MG:
      switch (unit) {
        case GoodServiceUnit.FIXED:
          return amount * quantity + adjustment * quantity;
        case GoodServiceUnit.PERCENTAGE:
          return amount * quantity + ((amount * adjustment) / 100) * quantity;
      }
      break;
  }
}

import { useEffect, useState } from 'react';
import {
  NavigateFunction,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import conf from '../../../config.json';
import { ContactErrorSetters } from '../../models/contactErrorSetters';
import {
  ClientResidence,
  ContactModel,
  ContactType,
  ForeignType,
  PersonType,
} from '../../models/contactModel';
import { CountryType } from '../../models/countryModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  setNewContactToInvoice,
  setNewContactToInvoiceStatus,
} from '../../redux/slices/newContactToInvoiceSlice';
import uri from '../../routers/quickfiscoUri.json';
import { ContactService } from '../../services/contactService';
import { UserService } from '../../services/userService';
import { SetState } from '../../types/functions';
import {
  InvoiceSign,
  NewContactInvoiceStatus,
  Operation,
  PromiseStatuses,
} from '../../types/strings';
import { ClientResidenceSelect } from '../clientResidenceSelect/clientResidenceSelect';
import { CountryTypeSelect } from '../countryTypeSelect/countryTypeSelect';
import { ForeignCustomerSelect } from '../foreignCustomerSelect/foreignCustomerSelect';
import { PersonTypeSelect } from '../personTypeSelect/personTypeSelect';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoError } from '../quickfiscoError/quickfiscoError';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoProvinceSelect } from '../quickfiscoProvinceSelect/quickfiscoProvinceSelect';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import { QuickfiscoTextarea } from '../quickfiscoTextarea/quickfiscoTextarea';
import { ForeignProvinceInfo } from '../foreignProvinceInfo/foreignProvinceInfo';
import { delItem, getItem } from '../../utils/localStorage';
import lang from './contactSaveOrEdit.json';
import './contactSaveOrEdit.css';
import { onlyLettersAndNumberRegex } from '../../regex/regex';

interface Props {
  contactType: ContactType;
  operationType: Operation;
  contactToEdit?: ContactModel;
}

interface Setters {
  setPersonType: SetState<PersonType | undefined>;
  setCompanyName: SetState<string | undefined>;
  setAddress: SetState<string | undefined>;
  setCity: SetState<string | undefined>;
  setZipCode: SetState<string | undefined>;
  setState: SetState<string | undefined>;
  setCountry: SetState<CountryType | undefined>;
  setVat: SetState<string | undefined>;
  setFiscalCode: SetState<string | undefined>;
  setSdi: SetState<string | undefined | null>;
  setPec: SetState<string | undefined | null>;
  setReferenceName: SetState<string | undefined | null>;
  setEmail: SetState<string | undefined>;
  setWebSite: SetState<string | undefined | null>;
  setPhone: SetState<string | undefined | null>;
  setFax: SetState<string | undefined | null>;
  setNotes: SetState<string | undefined | null>;
  setForeignType: SetState<ForeignType | undefined>;
  setClientResidence: SetState<ClientResidence | undefined>;
}

export function ContactSaveOrEdit(props: Props) {
  const { contactType } = props;
  const { operationType } = props;
  const { contactToEdit } = props;

  const [status, setStatus] = useState<PromiseStatuses>('idle');

  const [personType, setPersonType] = useState<PersonType | undefined>(
    undefined
  );
  const [companyName, setCompanyName] = useState<string | undefined>(undefined);
  const [address, setAddress] = useState<string | undefined>(undefined);
  const [city, setCity] = useState<string | undefined>(undefined);
  const [zipCode, setZipCode] = useState<string | undefined>(undefined);
  const [state, setState] = useState<string | undefined>(undefined);
  const [country, setCountry] = useState<CountryType | undefined>(
    CountryType.IT
  );
  const [vat, setVat] = useState<string | undefined>(undefined);
  const [fiscalCode, setFiscalCode] = useState<string | undefined>(undefined);
  const [sdi, setSdi] = useState<string | undefined | null>(undefined);
  const [pec, setPec] = useState<string | undefined | null>(undefined);
  const [referenceName, setReferenceName] = useState<string | undefined | null>(
    undefined
  );
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [webSite, setWebSite] = useState<string | undefined | null>(undefined);
  const [phone, setPhone] = useState<string | undefined | null>(undefined);
  const [fax, setFax] = useState<string | undefined | null>(undefined);
  const [notes, setNotes] = useState<string | undefined | null>(undefined);
  const [foreign, setForeignType] = useState<ForeignType | undefined>(
    undefined
  );
  const [residence, setClientResidence] = useState<ClientResidence | undefined>(
    undefined
  );

  const [errorCompanyName, setErrorCompanyName] = useState(false);
  const [errorPersonType, setErrorPersonType] = useState(false);
  const [errorForeignCustomerType, setErrorForeignCustomerType] =
    useState(false);
  const [errorClientResidence, setErrorClientResidence] = useState(false);
  const [errorAddress, setErrorAddress] = useState(false);
  const [errorCity, setErrorCity] = useState(false);
  const [errorZipCode, setErrorZipCode] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [errorCountry, setErrorCountry] = useState(false);
  const [errorVat, setErrorVat] = useState(false);
  const [errorFiscalCode, setErrorFiscalCode] = useState(false);
  const [errorPEC, setErrorPEC] = useState(false);
  const [errorSDI, setErrorSDI] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const isNewCustomer = searchParams.get('import');
  const isNewSupplier = searchParams.get('import');

  const userState = useAppSelector((state) => state.user);
  const newInvoiceContactState = useAppSelector(
    (state) => state.newContactToInvoice
  );

  const user = userState.user;
  const newInvoiceContactStatus = newInvoiceContactState.status;
  const newInvoiceContactInvoiceSign = newInvoiceContactState.invoiceSign;

  const contactService = new ContactService({
    setErrorCompanyName,
    setErrorPersonType,
    setErrorAddress,
    setErrorCity,
    setErrorZipCode,
    setErrorState,
    setErrorCountry,
    setErrorVat,
    setErrorFiscalCode,
    setErrorPEC,
    setErrorSDI,
    setErrorEmail,
    setErrorForeignCustomerType,
    setErrorClientResidence,
  });

  useEffect(() => {
    if (contactToEdit !== undefined) {
      setExistingValue(contactToEdit, {
        setPersonType,
        setCompanyName,
        setAddress,
        setCity,
        setZipCode,
        setState,
        setCountry,
        setVat,
        setFiscalCode,
        setSdi,
        setPec,
        setReferenceName,
        setEmail,
        setWebSite,
        setPhone,
        setFax,
        setNotes,
        setForeignType,
        setClientResidence,
      });
    }
  }, [contactToEdit]);

  useEffect(() => {
    if (personType === PersonType.PRIVATE_INDIVIDUAL && !contactToEdit?.sdi) {
      setSdi(conf.sdiPrivateIndividual);
    } else if (
      personType === PersonType.FOREIGN &&
      country !== CountryType.SM
    ) {
      setSdi(conf.sdiForeign);
    } else if (
      !contactToEdit?.sdi &&
      isNewCustomer !== 'true' &&
      isNewSupplier !== 'true'
    ) {
      setSdi('');
    }
  }, [personType, country]);

  useEffect(() => {
    if (isNewCustomer === 'true' && getItem('newCustomer')) {
      const retrievedObjectString = getItem('newCustomer');
      const retrievedObject =
        retrievedObjectString && JSON.parse(retrievedObjectString);

      setExistingValue(retrievedObject, {
        setPersonType,
        setCompanyName,
        setAddress,
        setCity,
        setZipCode,
        setState,
        setCountry,
        setVat,
        setFiscalCode,
        setSdi,
        setPec,
        setReferenceName,
        setEmail,
        setWebSite,
        setPhone,
        setFax,
        setNotes,
        setForeignType,
        setClientResidence,
      });
    } else if (isNewSupplier === 'true' && getItem('newSupplier')) {
      const retrievedObjectString = getItem('newSupplier');
      const retrievedObject =
        retrievedObjectString && JSON.parse(retrievedObjectString);

      setExistingValue(retrievedObject, {
        setPersonType,
        setCompanyName,
        setAddress,
        setCity,
        setZipCode,
        setState,
        setCountry,
        setVat,
        setFiscalCode,
        setSdi,
        setPec,
        setReferenceName,
        setEmail,
        setWebSite,
        setPhone,
        setFax,
        setNotes,
        setForeignType,
        setClientResidence,
      });
    }

    delItem('newCustomer');
    delItem('newSupplier');
  }, [isNewCustomer, isNewSupplier]);

  return (
    <div className={'row'}>
      <QuickfiscoSuccess
        active={status === 'successfully'}
        message={
          contactType === ContactType.CUSTOMER
            ? lang.ModificationSuccessCustomer
            : lang.ModificationSuccessSupplier
        }
        close={() => setStatus('idle')}
      />
      <QuickfiscoError
        active={status === 'failed'}
        message={
          contactType === ContactType.CUSTOMER
            ? lang.ModificationErrorCustomer
            : lang.ModificationErrorSupplier
        }
        close={() => setStatus('idle')}
      />
      <div className={'col-12 col-xl-6 mt-4'}>
        <div className={'row'}>
          <PersonTypeSelect
            id={
              contactType === ContactType.CUSTOMER
                ? 'contact-save-or-edit-customer-person-type-select'
                : 'contact-save-or-edit-supplier-person-type-select'
            }
            onChange={(_type) => {
              if (_type === PersonType.FOREIGN) {
                setCountry(undefined);
                setFiscalCode('');
                setState('EE');
              } else {
                setCountry(CountryType.IT);
                setState(undefined);
              }
              setPersonType(_type);
              contactService.validatePersonType(_type);
            }}
            error={errorPersonType}
            errorLabel={lang.errors.label12}
            value={personType}
          />
        </div>
        <div className={'contact-s-e-container p-4 mt-3'}>
          <div className={'row no-gutters'}>
            <div className="col-12">
              {personType === PersonType.FOREIGN &&
                contactType === ContactType.CUSTOMER && (
                  <div className="row mb-4">
                    <div className="col-6">
                      <ForeignCustomerSelect
                        id={'contact-save-or-edit-customer-foreign-type-select'}
                        value={foreign || undefined}
                        onChange={(_type) => {
                          setForeignType(_type);
                          contactService.validateForeignType(_type);
                        }}
                        error={errorForeignCustomerType}
                        errorLabel={lang.errors.label12}
                        required={
                          personType === PersonType.FOREIGN &&
                          contactType === ContactType.CUSTOMER
                            ? true
                            : false
                        }
                      />
                    </div>
                    <div className="col-6">
                      <ClientResidenceSelect
                        id={
                          'contact-save-or-edit-customer-client-residence-select'
                        }
                        value={residence || undefined}
                        onChange={(_type) => {
                          setClientResidence(_type);
                          contactService.validateClientResidence(_type);
                        }}
                        error={errorClientResidence}
                        errorLabel={lang.errors.label13}
                        required={
                          personType === PersonType.FOREIGN &&
                          contactType === ContactType.CUSTOMER
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                )}
              <QuickfiscoInput
                styleType={'default'}
                id={'customer-name'}
                type={'text'}
                label={
                  personType === PersonType.PRIVATE_INDIVIDUAL
                    ? 'Nome e Cognome'
                    : 'Denominazione/Nome Cliente'
                }
                placeholder={'Inserisci denominazione'}
                onChange={(e) => setCompanyName(e.target.value)}
                value={companyName || ''}
                error={errorCompanyName}
                errorLabel={lang.errors.label1}
                required={true}
                onBlur={(e) =>
                  contactService.validateCompanyName(e.target.value)
                }
              />
            </div>
          </div>
          <div className={'row no-gutters mt-3'}>
            <div className={'col-6'}>
              <QuickfiscoInput
                styleType={'default'}
                id={'customer-city'}
                type={'text'}
                label={'Comune'}
                placeholder={'Aggiungi comune'}
                onChange={(e) => setCity(e.target.value)}
                value={city || ''}
                error={errorCity}
                errorLabel={lang.errors.label3}
                required={true}
                onBlur={(e) => contactService.validateCity(e.target.value)}
              />
            </div>
            <div className={'col-6'}>
              <QuickfiscoInput
                styleType={'default'}
                id={'customer-address'}
                type={'text'}
                label={'Indirizzo'}
                placeholder={'Aggiungi indirizzo'}
                onChange={(e) => setAddress(e.target.value)}
                value={address || ''}
                error={errorAddress}
                errorLabel={lang.errors.label2}
                required={true}
                onBlur={(e) => contactService.validateAddress(e.target.value)}
              />
            </div>
          </div>
          <div className={'row no-gutters d-flex align-items-center mt-3'}>
            <div className={'col-6'}>
              <QuickfiscoInput
                styleType={'default'}
                id={'customer-zipCode'}
                type={'text'}
                label={'CAP'}
                placeholder={'Aggiungi CAP'}
                onChange={(e) => setZipCode(e.target.value)}
                value={zipCode || ''}
                error={errorZipCode}
                errorLabel={lang.errors.label4}
                required={true}
                maxLength={personType !== PersonType.FOREIGN ? 5 : undefined}
                onBlur={(e) =>
                  contactService.validateZipCode(e.target.value, personType)
                }
              />
            </div>
            <div className={'col-5 mb-3'}>
              <QuickfiscoProvinceSelect
                id={'customer-state'}
                value={state || undefined}
                onChange={(value) => {
                  setState(value);
                  contactService.validateState(value);
                }}
                error={errorState}
                errorLabel={lang.errors.label5}
                requiredField={true}
              />
            </div>
            <div className={'col-1 text-center'}>
              <ForeignProvinceInfo />
            </div>
          </div>
          {personType === PersonType.FOREIGN ? (
            <div className={'row no-gutters mt-3'}>
              <div className={'col-12'}>
                <CountryTypeSelect
                  id={
                    contactType === ContactType.CUSTOMER
                      ? 'contact-save-or-edit-customer-country-type-select'
                      : 'contact-save-or-edit-supplier-country-type-select'
                  }
                  value={country}
                  onChange={(_type) => {
                    setCountry(_type as CountryType);
                    contactService.validateCountry(_type, personType);
                  }}
                  error={errorCountry}
                  errorLabel={lang.errors.label6}
                  required={true}
                />
              </div>
            </div>
          ) : (
            <div className={'row no-gutters mt-3'}>
              <div className={'col-12'}>
                <QuickfiscoInput
                  styleType={'default'}
                  id={
                    contactType === ContactType.CUSTOMER
                      ? 'contact-save-or-edit-customer-country-type-select'
                      : 'contact-save-or-edit-supplier-country-type-select'
                  }
                  type={'text'}
                  label={'Nazione'}
                  value={country === CountryType.IT ? 'Italia' : ''}
                  error={errorCountry}
                  errorLabel={lang.errors.label6}
                  required={true}
                  disabled={true}
                />
              </div>
            </div>
          )}
          {personType !== PersonType.PRIVATE_INDIVIDUAL && (
            <div className={'row no-gutters mt-3'}>
              <QuickfiscoInput
                styleType={'default'}
                id={'customer-vat'}
                type={'text'}
                label={
                  personType === PersonType.GOV
                    ? 'P. IVA (compilare solo se la PA possiede P. IVA)'
                    : 'P. IVA'
                }
                placeholder={'Inserisci partita iva'}
                onChange={(e) => {
                  const validInput = e.target.value.replace(
                    onlyLettersAndNumberRegex,
                    ''
                  );
                  setVat(validInput);
                }}
                value={vat || ''}
                error={errorVat}
                errorLabel={lang.errors.label7}
                required={personType !== PersonType.GOV}
                onBlur={(e) =>
                  contactService.validateVat(e.target.value, personType)
                }
              />
            </div>
          )}
          {personType !== PersonType.FOREIGN && (
            <div className={'row no-gutters mt-3'}>
              <QuickfiscoInput
                styleType={'default'}
                id={'customer-fiscalCode'}
                type={'text'}
                label={'Codice Fiscale'}
                placeholder={'Inserisci codice fiscale'}
                onChange={(e) => {
                  setFiscalCode(e.target.value.toUpperCase());
                  if (personType === PersonType.PRIVATE_INDIVIDUAL) {
                    setVat(e.target.value.toUpperCase());
                  }
                }}
                value={fiscalCode || ''}
                error={errorFiscalCode}
                errorLabel={lang.errors.label8}
                required={true}
                onBlur={(e) =>
                  contactService.validateFiscalCode(e.target.value, personType)
                }
              />
            </div>
          )}
          {personType === PersonType.VAT && (
            <div>
              <div className={'row no-gutters mt-4'}>
                <div className={'col-12'}>
                  <span className={'contact-s-e-title'}>
                    {lang.electronicInvoicing}
                  </span>
                </div>
              </div>
              <div className={'row no-gutters'}>
                <div className={'col-12'}>
                  <span className={'contact-s-e-label'}>
                    {lang.electronicInvoicingLabel}
                  </span>
                </div>
              </div>
              <div className={'row no-gutters mt-1'}>
                <div className={'col-12'}>
                  <span className={'contact-s-e-error'}>
                    {errorSDI && lang.electronicInvoicingError}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className={'row no-gutters mt-3'}>
            {personType === PersonType.FOREIGN && country !== CountryType.SM ? (
              <QuickfiscoInput
                styleType={'default'}
                id={'customer-SDI'}
                type={'text'}
                label={'SDI'}
                placeholder={'SDI Per la fatturazione elettronica'}
                value={sdi || ''}
                required={true}
                onBlur={(e) =>
                  contactService.validateSdi(e.target.value, pec, personType)
                }
              />
            ) : (
              <QuickfiscoInput
                id={'customer-SDI'}
                type={'text'}
                styleType={'default'}
                label={
                  personType === PersonType.GOV
                    ? 'CUU Identificativo PA'
                    : 'SDI'
                }
                placeholder={
                  personType === PersonType.GOV
                    ? 'CUU Identificativo PA Per la fatturazione elettronica verso la Pubblica Amministrazione'
                    : 'SDI Per la fatturazione elettronica'
                }
                onChange={(e) => setSdi(e.target.value)}
                value={sdi || ''}
                error={errorSDI && personType !== PersonType.VAT}
                errorLabel={lang.errors.label9}
                required={personType !== PersonType.VAT}
                onBlur={(e) =>
                  contactService.validateSdi(e.target.value, pec, personType)
                }
              />
            )}
          </div>
          <div className={'row no-gutters mt-3'}>
            <QuickfiscoInput
              styleType={'default'}
              id={'customer-PEC'}
              type={'text'}
              label={'PEC'}
              placeholder={'Per la fatturazione elettronica'}
              onChange={(e) => setPec(e.target.value)}
              value={pec || ''}
              error={errorPEC}
              errorLabel={lang.errors.label10}
              onBlur={(e) => {
                contactService.validatePec(e.target.value);
                contactService.validateSdi(sdi, e.target.value, personType);
              }}
            />
          </div>
        </div>
      </div>
      <div className={'col-12 col-xl-6 mt-4'}>
        <div className={'contact-s-e-container p-4'}>
          <div className={'row no-gutters'}>
            <QuickfiscoInput
              styleType={'default'}
              id={'customer-nameSurname'}
              type={'text'}
              label={'Nome referente'}
              placeholder={'Nome e cognome'}
              onChange={(e) => setReferenceName(e.target.value)}
              value={referenceName || ''}
            />
          </div>
          <div className={'row no-gutters mt-3'}>
            <QuickfiscoInput
              styleType={'default'}
              id={'customer-email'}
              type={'text'}
              label={'Email'}
              placeholder={'Aggiungi email'}
              onChange={(e) => setEmail(e.target.value)}
              value={email || ''}
              error={errorEmail}
              errorLabel={lang.errors.label11}
              required={true}
              onBlur={(e) => contactService.validateEmail(e.target.value)}
            />
          </div>
          <div className={'row no-gutters mt-3'}>
            <QuickfiscoInput
              styleType={'default'}
              id={'customer-web'}
              type={'text'}
              label={'Sito web'}
              placeholder={'Aggiungi il sito web'}
              onChange={(e) => setWebSite(e.target.value)}
              value={webSite || ''}
            />
          </div>
          <div className={'row no-gutters mt-3'}>
            <QuickfiscoInput
              styleType={'default'}
              id={'customer-phone'}
              type={'text'}
              label={'Telefono'}
              placeholder={'Inserisci numero di cellulare o fisso'}
              onChange={(e) => setPhone(e.target.value)}
              value={phone || ''}
            />
          </div>
          <div className={'row no-gutters mt-3'}>
            <QuickfiscoInput
              styleType={'default'}
              id={'customer-FAX'}
              type={'text'}
              label={'FAX'}
              placeholder={'Inserisci numero di fax'}
              onChange={(e) => setFax(e.target.value)}
              value={fax || ''}
            />
          </div>
          <div className={'row no-gutters mt-3'}>
            <QuickfiscoTextarea
              id={'customer-note'}
              label={'Note extra'}
              placeholder={'Aggiungi una nota'}
              onChange={(e) => setNotes(e.target.value)}
              value={notes || ''}
            />
          </div>
          <div className={'row no-gutters mt-3  me-2 ms-2'}>
            {status === 'loading' ? (
              <QuickfiscoSpinner alignCenter={true} />
            ) : (
              <QuickfiscoButton
                id={'customer-save'}
                label={'SALVA ANAGRAFICA'}
                type={UserService.isNotEnabled(user) ? 'disabled' : 'secondary'}
                onClick={() =>
                  saveOrEdit(
                    contactType,
                    operationType,
                    {
                      id: contactToEdit && contactToEdit.id,
                      personType,
                      foreign,
                      residence,
                      companyName,
                      address,
                      city,
                      zipCode,
                      state,
                      country,
                      vat,
                      fiscalCode,
                      sdi,
                      pec,
                      referenceName,
                      email,
                      webSite,
                      phone,
                      fax,
                      notes,
                      type: contactType,
                      bankAccount: { bankIban: '', bankName: '' },
                    },
                    {
                      setErrorCompanyName,
                      setErrorPersonType,
                      setErrorForeignCustomerType,
                      setErrorClientResidence,
                      setErrorAddress,
                      setErrorCity,
                      setErrorZipCode,
                      setErrorState,
                      setErrorCountry,
                      setErrorVat,
                      setErrorSDI,
                      setErrorEmail,
                      setErrorFiscalCode,
                      setErrorPEC,
                    },
                    navigate,
                    setStatus,
                    newInvoiceContactStatus,
                    dispatch,
                    newInvoiceContactInvoiceSign
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

function saveOrEdit(
  contactType: ContactType,
  operationType: Operation,
  contact: ContactModel,
  contactErrorSetters: ContactErrorSetters,
  navigate: NavigateFunction,
  setStatus: SetState<PromiseStatuses>,
  newInvoiceContactStatus: NewContactInvoiceStatus,
  dispatch: Function,
  newInvoiceContactSign?: InvoiceSign
) {
  const service = new ContactService(contactErrorSetters);

  if (!service.validate(contact)) {
    return;
  }

  setStatus('loading');
  if (operationType === 'save') {
    service
      .save(contactType, contact)
      .then((data) => {
        if (newInvoiceContactStatus === 'toSave') {
          dispatch(setNewContactToInvoice(data));
          dispatch(setNewContactToInvoiceStatus('idle'));
          goToSave(navigate, newInvoiceContactSign);
        } else {
          goToList(contactType, navigate);
        }
      })
      .catch((err) => {
        console.error(err);
        setStatus('failed');
      });
  } else {
    service
      .edit(contactType, contact)
      .then(() => setStatus('successfully'))
      .catch((err) => {
        console.error(err);
        setStatus('failed');
      });
  }
}

function setExistingValue(contact: ContactModel, setters: Setters) {
  setters.setCompanyName(contact.companyName);
  setters.setFax(contact.fax);
  setters.setVat(contact.vat);
  setters.setAddress(contact.address);
  setters.setCity(contact.city);
  setters.setZipCode(contact.zipCode);
  setters.setState(contact.state);
  setters.setCountry(contact.country);
  setters.setPec(contact.pec);
  setters.setEmail(contact.email);
  if (contact.personType !== PersonType.FOREIGN)
    setters.setFiscalCode(contact.fiscalCode);
  setters.setNotes(contact.notes);
  setters.setPhone(contact.phone);
  setters.setReferenceName(contact.referenceName);
  setters.setWebSite(contact.webSite);
  setters.setSdi(contact.sdi);
  setters.setPersonType(contact.personType);
  setters.setForeignType(contact.foreign);
  setters.setClientResidence(contact.residence);
}

function goToList(contactType: ContactType, navigate: NavigateFunction) {
  if (contactType === ContactType.CUSTOMER) {
    navigate(uri.CustomersList);
  } else {
    navigate(uri.SuppliersList);
  }
}

function goToSave(navigate: NavigateFunction, invoiceSign?: InvoiceSign) {
  if (invoiceSign === 'customer') {
    navigate(uri.CustomerInvoiceSave);
  } else if (invoiceSign === 'supplier') {
    navigate(uri.SupplierInvoiceSave);
  } else if (invoiceSign === 'sts') {
    navigate(uri.StsInvoiceSave);
  } else if (invoiceSign === 'auto') {
    navigate(uri.AutoInvoiceSave);
  } else if (invoiceSign === 'proforma') {
    navigate(uri.ProformaInvoiceSave);
  }
}

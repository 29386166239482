import { useState } from 'react';
import { OnBlur, OnChange, OnKeyboardEvent } from '../../types/functions';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoLink } from '../quickfiscoLink/quickfiscoLink';
import './quickfiscoInput.css';

interface Props {
  id: string;
  type:
    | 'email'
    | 'hidden'
    | 'month'
    | 'number'
    | 'password'
    | 'search'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';
  styleType:
    | 'default'
    | 'large'
    | 'medium'
    | 'default-center'
    | 'large-center'
    | 'medium-center'
    | 'medium-wizard'
    | 'no-borders'
    | 'all-borders'
    | 'default-right';
  value?: string;
  defaultValue?: string;
  label?: string;
  maxLength?: number;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  error?: boolean;
  errorLabel?: string;
  hideLockIcon?: boolean;
  onBlur?: OnBlur;
  onChange?: OnChange;
  onKeyDown?: OnKeyboardEvent;
}

export function QuickfiscoInput(props: Props) {
  const { id } = props;
  const { type } = props;
  const { value } = props;
  const { defaultValue } = props;
  const { label } = props;
  const { maxLength } = props;
  const { disabled } = props;
  const { required } = props;
  const { styleType } = props;
  const { placeholder } = props;
  const { error } = props;
  const { errorLabel } = props;
  const { hideLockIcon } = props;
  const { onBlur } = props;
  const { onChange } = props;
  const { onKeyDown } = props;

  const [showPassword, setShowPassword] = useState(false);

  const isInvalid = error ? 'quickfisco-input-error' : '';

  const styleTypeMap = new Map<string, { label: string; input: string }>([
    [
      'default',
      {
        label: 'quickfisco-input-label',
        input: 'quickfisco-input-default',
      },
    ],
    [
      'large',
      {
        label: 'quickfisco-input-label',
        input: 'quickfisco-input-large',
      },
    ],
    [
      'medium',
      {
        label: 'quickfisco-input-label',
        input: 'quickfisco-input-medium',
      },
    ],
    [
      'medium-wizard',
      {
        label: 'quickfisco-input-label',
        input: 'quickfisco-input-medium-center',
      },
    ],
    [
      'default-center',
      {
        label: 'quickfisco-input-label-center',
        input: 'quickfisco-input-default quickfisco-input-center',
      },
    ],
    [
      'default-right',
      {
        label: 'quickfisco-input-label-right',
        input: 'quickfisco-input-default quickfisco-input-right',
      },
    ],
    [
      'large-center',
      {
        label: 'quickfisco-input-label-center',
        input: 'quickfisco-input-large quickfisco-input-center',
      },
    ],
    [
      'medium-center',
      {
        label: 'quickfisco-input-label-center',
        input: 'quickfisco-input-medium-center quickfisco-input-center',
      },
    ],
    [
      'no-borders',
      {
        label: 'quickfisco-input-label mb-2',
        input: 'quickfisco-input-no-borders',
      },
    ],
    [
      'all-borders',
      {
        label: '',
        input: 'quickfisco-input-all-borders',
      },
    ],
  ]);

  let _type = type;
  if (type === 'password' && showPassword) {
    _type = 'text';
  }

  const hidePasswordIcon: React.ReactNode = <QuickfiscoIcon name={'hide-password.svg'} />;
  const showPasswordIcon: React.ReactNode = <QuickfiscoIcon name={'show-password.svg'} />;

  return (
    <div
      className={
        styleType === 'all-borders' || styleType === 'no-borders' ? '' : 'mb-3'
      }
    >
      {label && (
        <label className={styleTypeMap.get(styleType)?.label}>
          {label}
          {label !== undefined && required && ' *'}
          {disabled && !hideLockIcon && <QuickfiscoIcon name={'block.svg'} className={'ps-2'} />}
        </label>
      )}
      <div className="d-flex align-items-end">
        <div className={'d-flex align-items-end w-100'}>
          <input
            id={id}
            type={_type}
            value={value}
            defaultValue={defaultValue}
            maxLength={maxLength}
            disabled={disabled}
            className={
              'quickfisco-input ' +
              styleTypeMap.get(styleType)?.input +
              ' ' +
              isInvalid
            }
            placeholder={placeholder + (required ? ' *' : '')}
            onBlur={(e) => onBlur && onBlur(e)}
            onChange={(e) => onChange && onChange(e)}
            onKeyDown={(e) => onKeyDown && onKeyDown(e)}
            autoComplete="new-password"
          />
          {type === 'password' && (
            <QuickfiscoLink
              id={'login-show-password'}
              icon={showPassword ? hidePasswordIcon : showPasswordIcon}
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
        </div>
      </div>
      {error && <div className="quickfisco-input-error mt-2">{errorLabel}</div>}
    </div>
  );
}

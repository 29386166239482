import { useEffect, useState } from 'react';
import { InvoiceModel } from '../../models/invoiceModel';
import { SetState } from '../../types/functions';
import { PromiseStatuses } from '../../types/strings';
import { EmptyList } from '../emptyList/emptyList';
import { InvoiceImportListRow } from '../invoiceImportListRow/invoiceImportListRow';
import { InvoiceImportModal } from '../invoiceImportModal/invoiceImportModal';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoSuccess } from '../quickfiscoSuccess/quickfiscoSuccess';
import './invoiceImportListComponent.css';
import lang from './invoiceImportListComponent.json';
import { InvoiceImportModel } from '../../models/invoiceImportModel';

interface Props {
  invoiceList: InvoiceModel[];
  status: PromiseStatuses;
  type: 'customer' | 'sts';
  checkedPrevoiusYearEnabled?: boolean;
  checkedThisYearEnabled?: boolean;
  setIdInvoicesFromPaid?: SetState<string[]>;
  idInvoicesFromPaid?: string[];
  getPaginationInvoices?: () => void;
  updatePaginationInvoices?: (
    index: number,
    updates: InvoiceImportModel
  ) => void;
  statusSaving: PromiseStatuses;
  setStatusSaving: SetState<PromiseStatuses>;
}

export function InvoiceImportListComponent(props: Props) {
  const { invoiceList } = props;
  const { status } = props;
  const { type } = props;
  const { checkedPrevoiusYearEnabled } = props;
  const { checkedThisYearEnabled } = props;
  const { setIdInvoicesFromPaid } = props;
  const { idInvoicesFromPaid } = props;
  const { updatePaginationInvoices } = props;
  const { getPaginationInvoices } = props;
  const { statusSaving } = props;
  const { setStatusSaving } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [indexInvoice, setIndexInvoice] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    if (statusSaving === 'successfully' && getPaginationInvoices) {
      getPaginationInvoices();
    }
  }, [statusSaving]);

  useEffect(() => {
    if (indexInvoice && indexInvoice > 0) {
      setOpen(true);
    }
  }, [indexInvoice]);

  if (status === 'loading') {
    return (
      <div className={'invoiceImportList-empty'}>
        <QuickfiscoSpinner />
      </div>
    );
  }

  if (status === 'failed') {
    return (
      <div className="invoiceImportList-empty">
        <div className={'p-blue'}>{lang.loadingError}</div>
      </div>
    );
  }

  if (invoiceList.length <= 0) {
    return (
      <div className={'invoiceImportList-empty'}>
        <EmptyList type={'import'} />
      </div>
    );
  }

  const rows = invoiceList.map((invoice, index) => {
    return (
      <div className="invoiceImportList-container mb-3" key={invoice.id}>
        <InvoiceImportListRow
          invoice={invoice}
          openInvoiceModal={() => setIndexInvoice(index + 1)}
          setStatusSaving={setStatusSaving}
          type={type}
          checkedPrevoiusYearEnabled={checkedPrevoiusYearEnabled}
          checkedThisYearEnabled={checkedThisYearEnabled}
          setIdInvoicesFromPaid={setIdInvoicesFromPaid}
          idInvoicesFromPaid={idInvoicesFromPaid}
          index={index}
          updatePaginationInvoices={updatePaginationInvoices}
        />
      </div>
    );
  });

  return (
    <>
      <QuickfiscoSuccess
        message={lang.editSuccess}
        active={statusSaving === 'successfully'}
        close={() => setStatusSaving('idle')}
      />
      {rows}
      {indexInvoice && invoiceList && (
        <InvoiceImportModal
          open={open}
          setOpen={(open: boolean) => {
            setOpen(open);
            setIndexInvoice(undefined);
          }}
          invoice={invoiceList[indexInvoice - 1]}
          setStatusSaving={setStatusSaving}
          type={type}
          index={indexInvoice}
          updatePaginationInvoices={updatePaginationInvoices}
        />
      )}
    </>
  );
}

import { useState } from 'react';
import { NumericFormat } from 'react-number-format';
import config from '../../../config.json';
import { InvoiceImportModel } from '../../models/invoiceImportModel';
import {
  InvoiceModel,
  InvoiceStatusType,
  InvoiceType,
  PaymentModeType,
} from '../../models/invoiceModel';
import { useAppSelector } from '../../redux/hooks';
import { CustomerInvoiceService } from '../../services/customerInvoiceService';
import { ImportInvoiceService } from '../../services/importInvoiceService';
import { SetState } from '../../types/functions';
import { PaymentType, PromiseStatuses } from '../../types/strings';
import {
  formatDateIT,
  formatDateUS,
  resolveUndefinedDate,
} from '../../utils/date';
import { formatNumberIT } from '../../utils/number';
import { AtecoSelect } from '../atecoSelect/atecoSelect';
import { fundsMap } from '../fundSelect/fundSelect';
import { PaymentStatusInfo } from '../paymentStatusInfo/paymentStatusInfo';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoDatePicker } from '../quickfiscoDatePicker/quickfiscoDatePicker';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoInputRadio } from '../quickfiscoInputRadio/quickfiscoInputRadio';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import { QuickfiscoYesNoSelect } from '../quickfiscoYesNoSelect/quickfiscoYesNoSelect';
import './invoiceImportModal.css';
import lang from './invoiceImportModal.json';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  invoice: InvoiceModel;
  index?: number;
  updatePaginationInvoices?: (
    index: number,
    updates: InvoiceImportModel
  ) => void;
  setStatusSaving: SetState<PromiseStatuses>;
  type: 'customer' | 'sts';
}

export function InvoiceImportModal(props: Props) {
  const { open } = props;
  const { setOpen } = props;
  const { invoice } = props;
  const { index } = props;
  const { updatePaginationInvoices } = props;
  const { setStatusSaving } = props;
  const { type } = props;

  const user = useAppSelector((state) => state.user.user);

  const totalAmount = CustomerInvoiceService.calculateTotalAmount(
    invoice,
    user,
    'customer'
  );

  const [status, setStatus] = useState<PromiseStatuses>('idle');
  const [atecoValueModal, setAtecoValueModal] = useState<string | undefined>(
    invoice.ateco === null ? undefined : invoice.ateco
  );
  const [socialContributionValueModal, setSocialContributionValueModal] =
    useState<boolean | undefined>(
      invoice.socialContribution === null
        ? undefined
        : invoice.socialContribution
    );
  const [paymentType, setPaymentType] = useState<PaymentType | undefined>(
    invoice.payments && invoice.payments[0].amount === totalAmount
      ? 'total'
      : invoice.payments && invoice.payments[0].amount < totalAmount
      ? 'partial'
      : invoice.status === InvoiceStatusType.REVERSED
      ? 'reverse'
      : undefined
  );
  const [receiptDate, setReceiptDate] = useState(new Date());
  const [amount, setAmount] = useState<number>(totalAmount);

  const paymentModeMap = new Map<PaymentModeType, string>([
    [PaymentModeType.MP01, 'Contanti'],
    [PaymentModeType.MP02, 'Assegno'],
    [PaymentModeType.MP05, 'Bonifico'],
    [PaymentModeType.MP08, 'CC / Pagamento Digitale'],
  ]);

  const editInvoice = () => {
    if (invoice.id) {
      const service = new ImportInvoiceService();

      if (type === 'sts') {
        setStatus('loading');
        service
          .editStsInvoice(invoice.id, {
            ateco: atecoValueModal,
            socialContribution: socialContributionValueModal,
          })
          .then(() => {
            setStatus('successfully');
            setStatusSaving('successfully');
            if (index && updatePaginationInvoices)
              updatePaginationInvoices(index - 1, { ateco: atecoValueModal });
            setOpen(false);
          })
          .catch((err) => {
            setStatus('failed');
            console.error(err);
          });
      } else {
        const paymentsPayload = [
          {
            amount,
            receiptDate,
          },
        ];

        setStatus('loading');
        service
          .editCustomerInvoice(invoice.id, {
            ateco: atecoValueModal,
            payments:
              paymentType &&
              paymentType != 'reverse' &&
              !(invoice.payments && invoice.payments[0].amount)
                ? paymentsPayload
                : undefined,
            status:
              paymentType === 'reverse'
                ? InvoiceStatusType.REVERSED
                : undefined,
          })
          .then(() => {
            setStatus('successfully');
            setStatusSaving('successfully');
            const payload: InvoiceImportModel = {
              ateco: atecoValueModal,
            };
            if (!(invoice.payments && invoice.payments[0].amount)) {
              payload.payments =
                paymentType && paymentType != 'reverse'
                  ? paymentsPayload
                  : undefined;
              payload.status =
                paymentType === 'reverse'
                  ? InvoiceStatusType.REVERSED
                  : (paymentType === 'partial' && totalAmount === amount) ||
                    paymentType === 'total'
                  ? InvoiceStatusType.PAID
                  : undefined;
            }
            if (index && updatePaginationInvoices)
              updatePaginationInvoices(index - 1, payload);
            setOpen(false);
          })
          .catch((err) => {
            setStatus('failed');
            console.error(err);
          });
      }
    }
  };

  return (
    <div className={'row mt-4'}>
      <QuickfiscoModal
        isOpen={open}
        hide={() => setOpen(false)}
        modalClassName={'invoice-import-modal'}
      >
        <div className="px-5">
          <div className="invoice-import-modal-title d-flex align-items-center">
            {lang.modalTitle}
            <QuickfiscoIcon name={'block.svg'} className="ms-2" />
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <div className="row">
                <div className="col-4">
                  <div className="invoice-import-modal-label">
                    {lang.documentType}
                  </div>
                  <div className="invoice-import-modal-value">
                    {invoice.type}{' '}
                    {invoice.type === InvoiceType.TD04
                      ? lang.creditSlip
                      : lang.invoice}
                  </div>
                </div>
                <div className="col-4">
                  <div className="invoice-import-modal-label">
                    {lang.documentNumber}
                  </div>
                  <div className="invoice-import-modal-value">
                    {invoice.number}
                  </div>
                </div>
                <div className="col-4">
                  <div className="invoice-import-modal-label">
                    {lang.documentDate}
                  </div>
                  <div className="invoice-import-modal-value">
                    {invoice.date && formatDateIT(invoice.date)}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-4">
                  <div className="invoice-import-modal-label">
                    {lang.client}
                  </div>
                  <div className="invoice-import-modal-value">
                    {invoice.customer?.companyName}
                  </div>
                </div>
                {type !== 'sts' && (
                  <div className="col-4">
                    <div className="invoice-import-modal-label">
                      {lang.vatNumber}
                    </div>
                    <div className="invoice-import-modal-value">
                      {invoice.customer?.fiscalCode}
                    </div>
                  </div>
                )}
                {type !== 'sts' && (
                  <div className="col-4">
                    <div className="invoice-import-modal-label">
                      {lang.fund}
                    </div>
                    <div className="invoice-import-modal-value">
                      {user.fund && fundsMap.get(user.fund)?.desc}
                    </div>
                  </div>
                )}
              </div>
              <div className="row mt-3">
                <div className="col-4">
                  <div className="invoice-import-modal-label">
                    {lang.goodservicesDescription}
                  </div>
                </div>
                <div className="col-4">
                  <div className="invoice-import-modal-label">
                    {lang.goodservicesQuantity}
                  </div>
                </div>
                <div className="col-4">
                  <div className="invoice-import-modal-label">
                    {lang.goodservicesAmount}
                  </div>
                </div>
              </div>
              {invoice.goodServices?.map((goodService, index) => (
                <div className="row mt-1" key={index}>
                  <div className="col-4">
                    <div className="invoice-import-modal-value">
                      {goodService.description}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="invoice-import-modal-value">
                      {goodService.quantity}
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="invoice-import-modal-value">
                      {formatNumberIT(goodService.amount) + config.EUR}
                    </div>
                  </div>
                </div>
              ))}
              <div className="row mt-3">
                {type === 'sts' && user.fund !== 'TC22' && (
                  <>
                    <div
                      className={type !== 'sts' ? 'col-4 offset-4' : 'col-4'}
                    >
                      <QuickfiscoYesNoSelect
                        id="invoice-import-yes-no-select"
                        label={lang.socialContribution}
                        onChange={(_socialContribution) => {
                          setSocialContributionValueModal(_socialContribution);
                        }}
                        value={socialContributionValueModal}
                      />
                    </div>
                    <div
                      className={type !== 'sts' ? 'col-4 offset-4' : 'col-4 '}
                    >
                      <div className="invoice-import-modal-label">
                        {lang.socialContributionAmount}
                      </div>
                      <div className="invoice-import-modal-value">
                        {socialContributionValueModal &&
                        invoice.socialContributionAmount != undefined
                          ? formatNumberIT(invoice.socialContributionAmount) +
                            config.EUR
                          : '0,00' + config.EUR}
                      </div>
                    </div>
                  </>
                )}
                {type !== 'sts' &&
                  invoice.reimbursementsOfExpenses?.map(
                    (reimbursementsOfExpense, index) => (
                      <div>
                        <div className="row" key={index}>
                          <div className="col-4">
                            <div className="invoice-import-modal-label">
                              {lang.reimbursment}
                            </div>
                          </div>
                          <div className="col-4 offset-4">
                            <div className="invoice-import-modal-label">
                              {lang.reimbursmentAmount}
                            </div>
                          </div>
                        </div>
                        <div className="row mt-1 mb-3" key={index}>
                          <div className="col-4">
                            <div className="invoice-import-modal-value">
                              {reimbursementsOfExpense.description}
                            </div>
                          </div>
                          <div className="col-4 offset-4">
                            <div className="invoice-import-modal-value">
                              {formatNumberIT(reimbursementsOfExpense.amount) +
                                config.EUR}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                {type !== 'sts' && (
                  <div className="col-4">
                    <div className="invoice-import-modal-label">
                      {lang.socialContribution_2}
                    </div>
                    <div className="invoice-import-modal-value">
                      {invoice.socialContribution ? 'SI' : 'NO'}
                    </div>
                  </div>
                )}
                {type !== 'sts' &&
                  invoice.socialContributionAmount !== null &&
                  invoice.socialContributionAmount !== undefined &&
                  invoice.socialContribution && (
                    <div className="col-4">
                      <div className="invoice-import-modal-label">
                        {lang.socialContributionAmount}
                      </div>
                      <div className="invoice-import-modal-value">
                        {formatNumberIT(invoice.socialContributionAmount) +
                          config.EUR}
                      </div>
                    </div>
                  )}
                <div
                  className={
                    type === 'sts' && user.fund !== 'TC22'
                      ? 'col-4'
                      : type !== 'sts' && invoice.socialContribution
                      ? 'col-4'
                      : !invoice.socialContribution
                      ? 'col-4 offset-4'
                      : 'col-4 offset-8'
                  }
                >
                  <div className="invoice-import-modal-label">
                    {lang.amount}
                  </div>
                  <div className="invoice-import-modal-value">
                    {formatNumberIT(totalAmount) + config.EUR}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-4">
                  <div className="invoice-import-modal-label">
                    {lang.paymentMode}
                  </div>
                  <div className="invoice-import-modal-value">
                    {invoice.paymentMode &&
                      paymentModeMap.get(invoice.paymentMode)}
                  </div>
                </div>
                <div className="col-4">
                  <div className="invoice-import-modal-label">
                    {lang.expirationDate}
                  </div>
                  <div className="invoice-import-modal-value">
                    {invoice.paymentExpiration &&
                      formatDateIT(invoice.paymentExpiration)}
                  </div>
                </div>
                {invoice.supplier?.bankAccount.bankIban && (
                  <div className="col-4">
                    <div className="invoice-import-modal-label">
                      {lang.iban}
                    </div>
                    <div className="invoice-import-modal-value">
                      {invoice.supplier?.bankAccount.bankIban}
                    </div>
                  </div>
                )}
              </div>
              <div className="row my-4">
                {type !== 'sts' && (
                  <div className="col-12">
                    <div className="invoice-import-modal-green-box px-4 py-3">
                      <div className="invoice-import-modal-green-box-text">
                        Sai che puoi salvare{' '}
                        <b>
                          l’anagrafica del cliente{' '}
                          {invoice.customer?.companyName}?
                        </b>
                        <br />
                        <span>Come fare:</span> 1) Importa la fattura 2) Accedi
                        alla scheda fattura 3) Salva l’anagrafica importata
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="invoice-import-modal-ateco-title d-flex align-items-center">
                    {lang.atecoTitle}
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <AtecoSelect
                        id={'invoiceImportListRow-ateco-select'}
                        label={lang.atecoLabel}
                        onChange={(_ateco) => setAtecoValueModal(_ateco)}
                        value={
                          atecoValueModal === null ? undefined : atecoValueModal
                        }
                        type={'own'}
                        requiredField={true}
                        toolTip={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                {invoice.type !== InvoiceType.TD04 && type !== 'sts' && (
                  <div className="col-12">
                    <div className="invoice-import-modal-ateco-title d-flex align-items-center">
                      {lang.paymentStatusTitle}
                      <div className="ms-2 mb-1">
                        <PaymentStatusInfo isCentered={true} />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className={'col-4'}>
                        <QuickfiscoInputRadio
                          name={'invoice-import-modal-paymentstatus-total'}
                          label={lang.totalPayment}
                          value={'total'}
                          key={'invoice-import-modal-total'}
                          id={'payment-status-total'}
                          checked={paymentType === 'total'}
                          onChange={() => {
                            if (
                              !(
                                invoice.payments && invoice.payments[0].amount
                              ) &&
                              !(invoice.status === InvoiceStatusType.REVERSED)
                            ) {
                              setPaymentType('total');
                              setAmount(totalAmount);
                            }
                          }}
                          disabled={
                            !!(
                              invoice.payments && invoice.payments[0].amount
                            ) || invoice.status === InvoiceStatusType.REVERSED
                          }
                        />
                      </div>
                      <div className={'col-4'}>
                        <QuickfiscoInputRadio
                          name={'invoice-import-modal-paymentstatus-partial'}
                          label={lang.partialPayment}
                          value={'partial'}
                          key={'invoice-import-modal-partial'}
                          id={'payment-status-partial'}
                          checked={paymentType === 'partial'}
                          onChange={() =>
                            !(invoice.payments && invoice.payments[0].amount) &&
                            !(invoice.status === InvoiceStatusType.REVERSED) &&
                            setPaymentType('partial')
                          }
                          disabled={
                            !!(
                              invoice.payments && invoice.payments[0].amount
                            ) || invoice.status === InvoiceStatusType.REVERSED
                          }
                        />
                      </div>
                      <div className={'col-4'}>
                        <QuickfiscoInputRadio
                          name={'invoice-import-modal-paymentstatus-reverse'}
                          label={lang.reversedPayment}
                          value={'reverse'}
                          key={'invoice-import-modal-reverse'}
                          id={'payment-status-reverse'}
                          checked={paymentType === 'reverse'}
                          onChange={() =>
                            !(invoice.payments && invoice.payments[0].amount) &&
                            !(invoice.status === InvoiceStatusType.REVERSED) &&
                            setPaymentType('reverse')
                          }
                          disabled={
                            !!(
                              invoice.payments && invoice.payments[0].amount
                            ) || invoice.status === InvoiceStatusType.REVERSED
                          }
                        />
                      </div>
                    </div>
                    {((paymentType && paymentType != 'reverse') ||
                      invoice.payments) && (
                      <div className={'row mt-1'}>
                        <div className={'col-6'}>
                          <QuickfiscoDatePicker
                            id={'invoice-import-modal-receiptDate'}
                            label={lang.receiptDate}
                            styleType={'default'}
                            maxDate={new Date()}
                            value={
                              invoice.payments &&
                              invoice.payments[0].receiptDate
                                ? new Date(invoice.payments[0].receiptDate)
                                : new Date(receiptDate)
                            }
                            onChange={(e) =>
                              e &&
                              setReceiptDate(
                                resolveUndefinedDate(formatDateUS(e))
                              )
                            }
                            disabled={
                              !!(invoice.payments && invoice.payments[0].amount)
                            }
                          />
                        </div>
                        <div className={'col-6'}>
                          <NumericFormat
                            key={'invoice-import-modal-amount'}
                            id={'invoice-import-modal-amount-input'}
                            suffix="€"
                            value={
                              invoice.payments && invoice.payments[0].amount
                                ? invoice.payments[0].amount
                                : amount
                            }
                            decimalSeparator=","
                            thousandSeparator="."
                            decimalScale={2}
                            onValueChange={(e) => {
                              e.floatValue && setAmount(e.floatValue);
                            }}
                            customInput={QuickfiscoInput}
                            styleType={'default'}
                            label={lang.leftAmount}
                            placeholder={'0,00€'}
                            fixedDecimalScale
                            required={true}
                            error={amount > totalAmount}
                            errorLabel={lang.leftAmountError}
                            disabled={
                              paymentType === 'total' ||
                              !!(invoice.payments && invoice.payments[0].amount)
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {!!(invoice.payments && invoice.payments[0].amount) &&
                type !== 'sts' && (
                  <div className="row d-flex justify-content-center">
                    <div className="col-12 py-3 px-4 d-flex mt-3 align-items-center invoice-import-modal-warn-payment-msg">
                      <QuickfiscoIcon
                        name={'alert-yellow-small.svg'}
                        className="quickfisco-input-cursor-pointer me-3"
                      />
                      <span>
                        {lang.warnPaymentMessage_1}{' '}
                        <b>{lang.warnPaymentMessage_2}</b>
                        {lang.warnPaymentMessage_3}
                      </span>
                    </div>
                  </div>
                )}
              <div className={'row my-4'}>
                <div className={'col-4 offset-2'}>
                  <QuickfiscoButton
                    id={'invoice-import-modal-close'}
                    type={'primary'}
                    label={lang.closeModal}
                    onClick={() => setOpen(false)}
                  />
                </div>
                <div className={'col-4'}>
                  {status === 'loading' ? (
                    <div
                      className={
                        'd-flex justify-content-center align-items-center w-100'
                      }
                    >
                      <QuickfiscoSpinner />
                    </div>
                  ) : (
                    <QuickfiscoButton
                      id={'invoice-import-modal-save'}
                      type={amount > totalAmount ? 'disabled' : 'secondary'}
                      label={lang.save}
                      onClick={() => editInvoice()}
                    />
                  )}
                </div>
              </div>
              {status === 'failed' && (
                <div className="invoice-import-modal-ateco-error mb-4">
                  {lang.savingError}
                </div>
              )}
            </div>
          </div>
        </div>
      </QuickfiscoModal>
    </div>
  );
}

import { useEffect } from 'react';
import { SingleValue } from 'react-select';
import { AtecoModel } from '../../models/atecoModel';
import { OptionModel } from '../../models/optionModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getAtecos } from '../../redux/slices/atecoSlice';
import { AtecoSelectInfo } from '../atecoSelectInfo/atecoSelectInfo';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import lang from './atecoSelect.json';

interface Props {
  id: string;
  value?: string;
  label?: string;
  error?: boolean;
  type: 'all' | 'own';
  onChange?: (ateco?: string) => void;
  errorLabel?: string;
  disabled?: boolean;
  requiredField?: boolean;
  index?: number;
  toolTip?: boolean;
  isGetAtecos?: boolean;
}

export function AtecoSelect(props: Props) {
  const { id } = props;
  const { value } = props;
  const { label } = props;
  const { error } = props;
  const { type } = props;
  const { onChange } = props;
  const { errorLabel } = props;
  const { disabled } = props;
  const { requiredField } = props;
  const { index } = props;
  const { toolTip } = props;
  const { isGetAtecos } = props;

  const dispatch = useAppDispatch();

  const atecoState = useAppSelector((state) => state.ateco);
  const userState = useAppSelector((state) => state.user);

  useEffect(() => {
    if (isGetAtecos === true) {
      dispatch(getAtecos());
    }
  }, [isGetAtecos]);

  useEffect(() => {
    if (
      atecoState.status === 'idle' &&
      atecoState.atecos.length === 0 &&
      type === 'all'
    ) {
      dispatch(getAtecos());
    }
  }, [atecoState.status]);

  if (atecoState.status === 'loading' || userState.status === 'loading') {
    return (
      <div className="row">
        <div className="col-12">
          <QuickfiscoSpinner />
        </div>
      </div>
    );
  }

  if (atecoState.status === 'failed' || !atecoState.atecos) {
    return (
      <div className="row">
        <div className="col-12">
          <span>{lang.errorStatus}</span>
        </div>
      </div>
    );
  }

  const allAtecoList = atecoState.atecos;
  const ownAtecoList = userState.user.atecos;

  let atecoLabel: string;
  switch (index) {
    case 0:
      atecoLabel = lang.AtecoLabel1;
      break;
    case 1:
      atecoLabel = lang.AtecoLabel2;
      break;
    case 2:
      atecoLabel = lang.AtecoLabel3;
      break;
    default:
      atecoLabel = lang.AtecoLabel;
  }

  return (
    <div className={'row d-flex align-items-center'}>
      <div className={'col'}>
        <QuickfiscoSelect
          id={id}
          requiredField={requiredField}
          label={label ? label : atecoLabel}
          styleType={'default'}
          error={error}
          errorLabel={errorLabel}
          onChange={(e: SingleValue<OptionModel>) => {
            if (e !== null) {
              onChange && onChange(e.value);
            }
          }}
          options={
            type === 'own'
              ? getOwnAtecoOptions(ownAtecoList, value)
              : getAllAtecoOptions(allAtecoList, value)
          }
          disabled={disabled}
          placeholder={lang.undefinedValue}
          value={
            type === 'own'
              ? getOwnAtecoOptions(ownAtecoList, value).filter(
                  (option) => option.selected === true
                )[0]
              : getAllAtecoOptions(allAtecoList, value).filter(
                  (option) => option.selected === true
                )[0]
          }
        />
      </div>
      {toolTip === true && (
        <div className={'col-auto'}>
          <AtecoSelectInfo />
        </div>
      )}
    </div>
  );
}

function getAllAtecoOptions(
  atecoList: AtecoModel[],
  value?: string
): OptionModel[] {
  let atecoOptions: OptionModel[] = [];

  atecoOptions.push({
    label: 'Seleziona il codice ateco',
    value: undefined,
    selected: value === undefined,
  });

  for (let atecoCode of atecoList) {
    atecoOptions.push({
      value: atecoCode.code,
      label: atecoCode.code !== undefined ? atecoCode.code : '',
      selected: value === atecoCode.code,
    });
  }

  return atecoOptions;
}

function getOwnAtecoOptions(
  atecoList?: string[],
  value?: string
): OptionModel[] {
  const atecoOptions: OptionModel[] = [];

  if (atecoList === undefined) {
    return atecoOptions;
  }

  for (let atecoCode of atecoList) {
    atecoOptions.push({
      label: atecoCode,
      value: atecoCode,
      selected: value === atecoCode,
    });
  }

  return atecoOptions;
}

import { useEffect, useState } from 'react';
import { v4 as uiidv4 } from 'uuid';
import { ReimbursementOfExpenseModel } from '../../models/invoiceModel';
import { formatNumberIT, parseNumber } from '../../utils/number';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import { QuickfiscoInputFile } from '../quickfiscoInputFile/quickfiscoInputFile';
import { QuickfiscoModal } from '../quickfiscoModal/quickfiscoModal';
import './reimbursementOfExpensesInput.css';
import lang from './reimbursementOfExpensesInput.json';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
  error: boolean;
  addReimbursementOfExpense: (value: ReimbursementOfExpenseModel) => boolean;
}

export function ReimbursementOfExpensesInput(props: Props) {
  const { open } = props;
  const { setOpen } = props;
  const { error } = props;
  const { addReimbursementOfExpense } = props;

  const [file, setFile] = useState<File>();
  const [description, setDescription] = useState<string>('');
  const [amount, setAmount] = useState<number>(0);

  useEffect(() => {
    if(open) {
      setDescription('')
      setAmount(0)
      setFile(undefined)
    }
  },[open])

  return (
    <div>
      <QuickfiscoModal hide={() => setOpen(false)} isOpen={open}>
        <div className={'reimbursement-of-expense-input-container'}>
          <div className={'row'}>
            <div className={'col-12'}>
              <span className={'reimbursement-of-expense-input-title'}>
                {lang.title}
              </span>
            </div>
          </div>
          <div className={'row mt-3'}>
            <div className={'col-9'}>
              <QuickfiscoInput
                id='reimbursement-of-expense-description-input'
                type={'text'}
                value={description || ''}
                styleType={'default'}
                label={lang.description}
                placeholder={lang.descriptionPlaceholder}
                required
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className={'col-3'}>
              <QuickfiscoInput
                id='reimbursement-of-expense-amount-input'
                type={'text'}
                defaultValue={formatNumberIT(amount)}
                styleType={'default'}
                label={lang.amountLabel}
                required
                onChange={(e) => setAmount(parseNumber(e.target.value))}
              />
            </div>
          </div>
          <div className={'row mt-1'}>
            <div className={'col-12'}>
              <p className={'reimbursement-of-expense-input-gray'}>
                {lang.warning.amount}
              </p>
            </div>
          </div>
          <div className={'row mt-4'}>
            <div className={'col-12'}>
              <QuickfiscoInputFile
                maxHeight={'100px'}
                multiple={false}
                fileList={file ? [file] : []}
                setFileList={(_file) => setFile(_file[0])}
                preview={true}
              />
            </div>
          </div>
          <div className={'row mt-5 d-flex justify-content-center'}>
            <div className={'col-7'}>
              <QuickfiscoButton
                id={'reimbursement-of-expenses-input-button'}
                label={lang.buttonLabel}
                type={'primary'}
                onClick={() => {
                  const ok = addReimbursementOfExpense(
                    {
                      key: uiidv4(),
                      description,
                      amount,
                      file
                    }
                  );
                  if (ok) {
                    setAmount(0);
                    setFile(undefined);
                    setOpen(false);
                  }
                }}
              />
              {
                error && (
                  <div className={'row mt-2'}>
                    <div className={'col-12 text-center'}>
                      <div className={'reimbursement-of-expense-input-error'}>
                        {lang.errorField}
                      </div>
                      <div className={'reimbursement-of-expense-input-error'}>
                        {lang.errorAttachment}
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </QuickfiscoModal>
    </div>
  );
}

import React, { useState } from 'react';
import { isAfter } from 'date-fns';
import { ComponentVisibleOutput } from '../../hooks/componentVisible';
import { FeesRegisterModel } from '../../models/feesRegisterModel';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { feesRegisterDelete, feesRegisterFindAll, feesRegisterFindById } from '../../redux/slices/feesRegisterSlice';
import { FeesRegisterEditOrSave } from '../feesRegisterEdit/feesRegisterEdit';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoSpinner } from '../quickfiscoSpinner/quickfiscoSpinner';
import './feesRegisterMenu.css';

interface Props {
    feesRegister: FeesRegisterModel;
    innerRef?: string | ((instance: (HTMLDivElement | null)) => void) | React.RefObject<HTMLDivElement> | null;
    componentVisible: ComponentVisibleOutput;
}

export function FeesRegisterMenu(props: Props) {
    const { innerRef } = props;
    const { componentVisible } = props;
    const { feesRegister } = props;

    const feesRegisterState = useAppSelector(state => state.feesRegister)

    const [openModal, setOpenModal] = useState(false);

    const dispatch = useAppDispatch();

    const feesRegisterId = feesRegister.id

    return (
        <div>
            {
                componentVisible.isVisible && (
                    <div className={'fees-register-menu-container'} ref={innerRef}>
                        <div>
                        {!isAfter(feesRegister.date, new Date()) && (
                            <>
                                <div
                                    className={'fees-register-menu-item'}
                                    onClick={() => {
                                    if (feesRegisterId !== undefined) {
                                        dispatch(feesRegisterFindById(feesRegisterId));
                                    }
                                    componentVisible.setIsVisible(false);
                                    setOpenModal(true);
                                    }}
                                >
                                    <div className={'fees-register-menu-icon'}>
                                    <QuickfiscoIcon name={'find.svg'} />
                                    </div>
                                    Apri Scheda
                                </div>
                                <div className={'fees-register-menu-line mt-2 mb-2'} />
                            </>
                        )}
                        </div>
                        <div>
                            {
                                feesRegisterState.editStatus === 'loading' ? (
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <QuickfiscoSpinner />
                                    </div>
                                ) : (
                                    <div
                                        className={'fees-register-menu-item'}
                                        onClick={() => {
                                            feesRegister &&
                                                dispatch(feesRegisterDelete({
                                                    id: feesRegisterId,
                                                }))
                                                    .then(() => {
                                                        dispatch(feesRegisterFindAll({
                                                            month: feesRegisterState.monthFilter,
                                                            year: feesRegisterState.yearFilter
                                                        }));
                                                    })
                                        }}
                                    >
                                        <div className={'fees-register-menu-icon'}>
                                            <QuickfiscoIcon name={'delete-icon.svg'} />
                                        </div>
                                        Svuota
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
            <FeesRegisterEditOrSave
                feesRegister={feesRegister}
                open={openModal}
                setOpen={() => { setOpenModal(false); componentVisible.setIsVisible(false) }}
            />
        </div>
    );
}

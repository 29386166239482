import { SingleValue } from 'react-select';
import { OptionModel } from '../../models/optionModel';
import { useAppSelector } from '../../redux/hooks';
import { QuickfiscoSelect } from '../quickfiscoSelect/quickfiscoSelect';

interface Props {
  value?: number;
  onChange?: (index?: number) => void;
}

export function GoodServiceSelect(props: Props) {
  const { onChange } = props;
  const { value } = props;

  const customerInvoiceState = useAppSelector((state) => state.customerInvoice);
  const lenGoodService = customerInvoiceState.invoice.goodServices?.length;

  return (
    <QuickfiscoSelect
      id={'good-service-select'}
      label={'Seleziona un prodotto/servizio'}
      onChange={(e: SingleValue<OptionModel>) => {
        if (e !== null) {
          // @ts-ignore
          onChange && onChange(e.value);
        }
      }}
      placeholder='Seleziona'
      options={getOptions(value, lenGoodService)}
      requiredField={true}
      styleType={'default'}
      value={
        getOptions(value, lenGoodService).filter(
          (option) => option.selected === true
        )[0]
      }
    />
  );
}

function getOptions(value?: number, lenGoodService?: number): OptionModel[] {
  let optionList: OptionModel[] = [];

  if (lenGoodService) {
    for (let i = 0; i < lenGoodService; i++) {
      optionList.push({
        label: `Prodotto/servizio ${i + 1}`,
        value: `${i}`, 
        selected: value === i,
      });
    }
  }

  return optionList;
}

import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuItem,
  MenuItemStyles,
  Sidebar,
  SubMenu,
  menuClasses,
  useProSidebar,
} from 'react-pro-sidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { doLogout } from '../../redux/slices/loginSlice';
import { getUnreadCounters } from '../../redux/slices/messagesListSlice';
import { setProfileNotSavedModalIsOpen } from '../../redux/slices/userSlice';
import { UserService } from '../../services/userService';
import { getItem, setItem } from '../../utils/localStorage';
import { BringAFriend } from '../bringAFriend/bringAFriend';
import { MenuCollapsedInfo } from '../menuCollapsedInfo/menuCollapsedInfo';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { Spinner } from 'react-bootstrap';
import config from '../../../config.json';
import uri from '../../routers/quickfiscoUri.json';
import './quickfiscoMenu.css';

export enum MenuItems {
  HOME = 0,
  CUSTOMER_INVOICE = 1,
  SUPPLIER_INVOICE = 2,
  CONTACTS_CUSTOMER = 3,
  CONTACTS_SUPPLIER = 4,
  PROFILE = 5,
  STS_CONFIGURATION = 6,
  STS_HISTORY = 7,
  AUTO_INVOICE = 8,
  TAX_CALCULATION = 9,
  FEES_REGISTER = 10,
  TAX_DECLARATION = 11,
  PROFORMA_INVOICE = 12,
  OCCASIONAL_JOB_INVOICE = 13,
  CREDIT_SLIP = 14,
  DOCUMENTS = 15,
  MESSAGES = 16,
  BRINGAFRIENDMODAL = 17,
  LOGOUT = 18,
  FINANCE = 19,
  TAX_RETURN = 20,
}

export interface MenuAttributes {
  label: string;
  className: string;
  textClassName: string;
  linkTo: string;
  icon?: React.ReactElement;
}

interface Props {
  id: string;
  menuItem: MenuItems;
}

export function QuickfiscoMenu(props: Props) {
  const { menuItem } = props;
  const location = useLocation();

  const [bringAFriendModalIsOpen, setBringAFriendModalIsOpen] =
    useState<boolean>(false);

  const { collapseSidebar, collapsed } = useProSidebar();

  const userState = useAppSelector((state) => state.user);
  const unreadCounters = useAppSelector(
    (state) => state.messagesList.unreadCounters
  );

  const user = useAppSelector(() => userState.user);
  const editUserRequest = userState.editUserRequest;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const homeAttributes = getAttributes(menuItem, MenuItems.HOME, collapsed);
  const customerInvoiceAttributes = getAttributes(
    menuItem,
    MenuItems.CUSTOMER_INVOICE,
    collapsed
  );
  const supplierInvoiceAttributes = getAttributes(
    menuItem,
    MenuItems.SUPPLIER_INVOICE,
    collapsed
  );
  const contactsCustomerAttributes = getAttributes(
    menuItem,
    MenuItems.CONTACTS_CUSTOMER,
    collapsed
  );
  const contactsSupplierAttributes = getAttributes(
    menuItem,
    MenuItems.CONTACTS_SUPPLIER,
    collapsed
  );
  const stsConfigurationAttributes = getAttributes(
    menuItem,
    MenuItems.STS_CONFIGURATION,
    collapsed
  );
  const stsHistoryAttributes = getAttributes(
    menuItem,
    MenuItems.STS_HISTORY,
    collapsed
  );
  const autoInvoiceAttributes = getAttributes(
    menuItem,
    MenuItems.AUTO_INVOICE,
    collapsed
  );
  const taxCalculationAttributes = getAttributes(
    menuItem,
    MenuItems.TAX_CALCULATION,
    collapsed
  );
  const feesRegisterAttributes = getAttributes(
    menuItem,
    MenuItems.FEES_REGISTER,
    collapsed
  );
  const proformaInvoiceAttributes = getAttributes(
    menuItem,
    MenuItems.PROFORMA_INVOICE,
    collapsed
  );
  const occasionalJobInvoiceAttributes = getAttributes(
    menuItem,
    MenuItems.OCCASIONAL_JOB_INVOICE,
    collapsed
  );
  const creditSlipInvoiceAttributes = getAttributes(
    menuItem,
    MenuItems.CREDIT_SLIP,
    collapsed
  );
  const documentsAttributes = getAttributes(
    menuItem,
    MenuItems.DOCUMENTS,
    collapsed
  );
  const messagesAttributes = getAttributes(
    menuItem,
    MenuItems.MESSAGES,
    collapsed
  );
  const bringAFrinedAttributes = getAttributes(
    menuItem,
    MenuItems.BRINGAFRIENDMODAL,
    collapsed
  );
  const logoutAttributes = getAttributes(menuItem, MenuItems.LOGOUT, collapsed);
  const financeAttributes = getAttributes(
    menuItem,
    MenuItems.FINANCE,
    collapsed
  );
  const taxReturnAttributes = getAttributes(
    menuItem,
    MenuItems.TAX_RETURN,
    collapsed
  );

  const editingMe = UserService.editingMe(
    user,
    editUserRequest,
    user.status !== 4
  );

  const collapseSidebarStatus = getItem('collapseSidebarStatus') === 'true';

  useEffect(() => {
    collapseSidebar(collapseSidebarStatus);
    if (
      location.pathname === '/documents' ||
      location.pathname === '/messages'
    ) {
      dispatch(getUnreadCounters());
    }
  }, []);

  const onClickCollapseSidebar = () => {
    collapseSidebar(!collapseSidebarStatus);
    setItem('collapseSidebarStatus', JSON.stringify(!collapseSidebarStatus));
  };

  if (
    !customerInvoiceAttributes ||
    !supplierInvoiceAttributes ||
    !autoInvoiceAttributes ||
    !contactsCustomerAttributes ||
    !contactsSupplierAttributes ||
    !stsConfigurationAttributes ||
    !stsHistoryAttributes ||
    !proformaInvoiceAttributes ||
    !occasionalJobInvoiceAttributes ||
    !creditSlipInvoiceAttributes
  ) {
    return <></>;
  }

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: '13px',
      fontWeight: 400,
      backgroundColor: 'white',
      display: collapsed ? 'flex' : '',
      alignItems: collapsed ? 'center' : '',
      justifyContent: collapsed ? 'center' : '',
      transition: 'width, left, right, 500ms',
      padding: collapsed ? '7px 20px' : '0 10px',
    },
    button: {
      font: 'normal normal normal 13px/21px Poppins',
      fontWeight: 500,
      letterSpacing: '0px',
      color: 'black',
      position: 'relative',
      borderRadius: collapsed ? '5px' : '7px',
      margin: collapsed ? '5px 10px' : '3px 10px',
      padding: collapsed ? 0 : '',
      width: collapsed ? '40px' : '',
      height: collapsed ? '35px' : '35px',
      display: collapsed ? 'flex' : '',
      alignItems: collapsed ? 'center' : '',
      justifyContent: collapsed ? 'center' : '',
      [`&.${menuClasses.active}`]: {
        backgroundColor: '#4643D3',
        color: 'white',
      },
      [`&.${menuClasses.active}:hover`]: {
        background: '#9DADFC',
        color: 'white',
      },
      '&:hover': {
        background: '#9DADFC',
        color: 'black',
      },
      transition: 'width, left, right, 500ms',
    },
    icon: {
      margin: collapsed ? '0' : '',
      width: collapsed ? '100%' : '',
      transition: 'width, left, right, 500ms',
    },
    subMenuContent: {
      ['.' + menuClasses.button]: {
        background: 'white 0% 0% no-repeat padding-box',
        fontWeight: 400,
        color: 'black',
        justifyContent: 'end',
        margin: '0 0 0 30px',
        [`&.${menuClasses.active}`]: {
          fontWeight: '600',
          color: '#4643D3',
          textDecoration: 'underline',
        },
        [`&.${menuClasses.active}:hover`]: {
          fontWeight: '600',
          color: '#4643D3',
          textDecoration: 'underline',
          background: 'white',
        },
        '&:hover': {
          fontWeight: '400',
          color: '#4643D3',
          textDecoration: 'underline',
        },
      },
    },
  };

  return (
    <div className="quickfisco-menu-container">
      <div className="quickfisco-menu-logo py-4">
        <QuickfiscoIcon
          name={collapsed ? 'logoFav.svg' : 'logo.svg'}
          onClick={() => {
            if (editingMe && menuItem === MenuItems.PROFILE) {
              dispatch(setProfileNotSavedModalIsOpen(true));
            } else {
              navigate(uri.Home);
            }
          }}
        />
      </div>
      <div className="primary-menu">
        <Sidebar
          id="sidebar-menu-main"
          collapsedWidth="120px"
          transitionDuration={300}
          rootStyles={{
            backgroundColor: 'white',
            boxShadow: '14px -13px 11px 7px #00000014',
            border: 0,
            zIndex: 4,
            width: '280px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Menu
            menuItemStyles={menuItemStyles}
            className="quickfisco-menu-main"
          >
            <MenuItem
              active={menuItem === MenuItems.HOME}
              component={setView(menuItem, editingMe, homeAttributes?.linkTo)}
              onClick={() =>
                notSavedProfileCheck(editingMe, menuItem, dispatch)
              }
              icon={homeAttributes?.icon}
            >
              {!collapsed && homeAttributes?.label}
              {collapsed && <MenuCollapsedInfo label={'Dashboard'} />}
            </MenuItem>
            {user.status !== 6 && (
              <>
                {!collapsed && (
                  <p className="quickfisco-menu-section">
                    CONTABILITÀ E FATTURE
                  </p>
                )}
                <SubMenu
                  label={
                    <div>
                      {!collapsed && 'Fatturazione'}
                      {collapsed && (
                        <MenuCollapsedInfo
                          label={'Fatturazione'}
                          subMenuItems={[
                            customerInvoiceAttributes,
                            creditSlipInvoiceAttributes,
                            supplierInvoiceAttributes,
                            autoInvoiceAttributes,
                            proformaInvoiceAttributes,
                            occasionalJobInvoiceAttributes,
                          ]}
                        />
                      )}
                    </div>
                  }
                  active={
                    menuItem === MenuItems.CUSTOMER_INVOICE ||
                    menuItem === MenuItems.AUTO_INVOICE ||
                    menuItem === MenuItems.SUPPLIER_INVOICE ||
                    menuItem === MenuItems.PROFORMA_INVOICE ||
                    menuItem === MenuItems.OCCASIONAL_JOB_INVOICE ||
                    menuItem === MenuItems.CREDIT_SLIP
                  }
                  open={
                    menuItem === MenuItems.CUSTOMER_INVOICE ||
                    menuItem === MenuItems.AUTO_INVOICE ||
                    menuItem === MenuItems.SUPPLIER_INVOICE ||
                    menuItem === MenuItems.PROFORMA_INVOICE ||
                    menuItem === MenuItems.OCCASIONAL_JOB_INVOICE ||
                    menuItem === MenuItems.CREDIT_SLIP
                  }
                  component={setView(
                    menuItem,
                    editingMe,
                    customerInvoiceAttributes?.linkTo
                  )}
                  onClick={() =>
                    notSavedProfileCheck(editingMe, menuItem, dispatch)
                  }
                  icon={customerInvoiceAttributes.icon}
                >
                  {!collapsed && (
                    <div>
                      <MenuItem
                        active={menuItem === MenuItems.CUSTOMER_INVOICE}
                        component={setView(
                          menuItem,
                          editingMe,
                          customerInvoiceAttributes?.linkTo
                        )}
                        onClick={() =>
                          notSavedProfileCheck(editingMe, menuItem, dispatch)
                        }
                      >
                        {!collapsed && customerInvoiceAttributes?.label}
                      </MenuItem>
                      <MenuItem
                        active={menuItem === MenuItems.CREDIT_SLIP}
                        component={setView(
                          menuItem,
                          editingMe,
                          creditSlipInvoiceAttributes?.linkTo
                        )}
                        onClick={() =>
                          notSavedProfileCheck(editingMe, menuItem, dispatch)
                        }
                      >
                        {!collapsed && creditSlipInvoiceAttributes?.label}
                      </MenuItem>
                      <MenuItem
                        active={menuItem === MenuItems.SUPPLIER_INVOICE}
                        component={setView(
                          menuItem,
                          editingMe,
                          supplierInvoiceAttributes?.linkTo
                        )}
                        onClick={() =>
                          notSavedProfileCheck(editingMe, menuItem, dispatch)
                        }
                      >
                        {!collapsed && supplierInvoiceAttributes?.label}
                      </MenuItem>
                      <MenuItem
                        active={menuItem === MenuItems.PROFORMA_INVOICE}
                        component={setView(
                          menuItem,
                          editingMe,
                          proformaInvoiceAttributes?.linkTo
                        )}
                        onClick={() =>
                          notSavedProfileCheck(editingMe, menuItem, dispatch)
                        }
                      >
                        {!collapsed && proformaInvoiceAttributes?.label}
                      </MenuItem>
                      <MenuItem
                        active={menuItem === MenuItems.AUTO_INVOICE}
                        component={setView(
                          menuItem,
                          editingMe,
                          autoInvoiceAttributes?.linkTo
                        )}
                        onClick={() =>
                          notSavedProfileCheck(editingMe, menuItem, dispatch)
                        }
                      >
                        {!collapsed && autoInvoiceAttributes?.label}
                      </MenuItem>
                      <MenuItem
                        active={menuItem === MenuItems.OCCASIONAL_JOB_INVOICE}
                        component={setView(
                          menuItem,
                          editingMe,
                          occasionalJobInvoiceAttributes?.linkTo
                        )}
                        onClick={() =>
                          notSavedProfileCheck(editingMe, menuItem, dispatch)
                        }
                      >
                        {!collapsed && occasionalJobInvoiceAttributes?.label}
                      </MenuItem>
                    </div>
                  )}
                </SubMenu>
                {user.feesRegisterEnabled && (
                  <MenuItem
                    active={menuItem === MenuItems.FEES_REGISTER}
                    component={setView(
                      menuItem,
                      editingMe,
                      feesRegisterAttributes?.linkTo
                    )}
                    onClick={() =>
                      notSavedProfileCheck(editingMe, menuItem, dispatch)
                    }
                    icon={feesRegisterAttributes?.icon}
                  >
                    {!collapsed && feesRegisterAttributes?.label}
                    {collapsed && <MenuCollapsedInfo label={'Corrispettivi'} />}
                  </MenuItem>
                )}
                <MenuItem
                  active={menuItem === MenuItems.TAX_CALCULATION}
                  component={setView(
                    menuItem,
                    editingMe,
                    taxCalculationAttributes?.linkTo
                  )}
                  onClick={() =>
                    notSavedProfileCheck(editingMe, menuItem, dispatch)
                  }
                  icon={taxCalculationAttributes?.icon}
                >
                  {!collapsed && taxCalculationAttributes?.label}
                  {collapsed && (
                    <MenuCollapsedInfo label={'Previsionale tasse'} />
                  )}
                </MenuItem>
                <SubMenu
                  label={
                    <div>
                      {!collapsed && 'Anagrafiche'}
                      {collapsed && (
                        <MenuCollapsedInfo
                          label={'Anagrafiche'}
                          subMenuItems={[
                            contactsCustomerAttributes,
                            contactsSupplierAttributes,
                          ]}
                        />
                      )}
                    </div>
                  }
                  active={
                    menuItem === MenuItems.CONTACTS_CUSTOMER ||
                    menuItem === MenuItems.CONTACTS_SUPPLIER
                  }
                  open={
                    menuItem === MenuItems.CONTACTS_CUSTOMER ||
                    menuItem === MenuItems.CONTACTS_SUPPLIER
                  }
                  component={setView(
                    menuItem,
                    editingMe,
                    contactsCustomerAttributes?.linkTo
                  )}
                  onClick={() =>
                    notSavedProfileCheck(editingMe, menuItem, dispatch)
                  }
                  icon={contactsCustomerAttributes.icon}
                >
                  {!collapsed && (
                    <div>
                      <MenuItem
                        active={menuItem === MenuItems.CONTACTS_CUSTOMER}
                        component={setView(
                          menuItem,
                          editingMe,
                          contactsCustomerAttributes?.linkTo
                        )}
                        onClick={() =>
                          notSavedProfileCheck(editingMe, menuItem, dispatch)
                        }
                      >
                        {contactsCustomerAttributes?.label}
                      </MenuItem>
                      <MenuItem
                        active={menuItem === MenuItems.CONTACTS_SUPPLIER}
                        component={setView(
                          menuItem,
                          editingMe,
                          contactsSupplierAttributes?.linkTo
                        )}
                        onClick={() =>
                          notSavedProfileCheck(editingMe, menuItem, dispatch)
                        }
                      >
                        {contactsSupplierAttributes?.label}
                      </MenuItem>
                    </div>
                  )}
                </SubMenu>
                <MenuItem
                  active={menuItem === MenuItems.TAX_RETURN}
                  component={setView(
                    menuItem,
                    editingMe,
                    taxReturnAttributes?.linkTo
                  )}
                  onClick={() =>
                    notSavedProfileCheck(editingMe, menuItem, dispatch)
                  }
                  icon={taxReturnAttributes?.icon}
                >
                  {!collapsed && taxReturnAttributes?.label}
                  {collapsed && (
                    <MenuCollapsedInfo label={'Dichiarazione redditi'} />
                  )}
                </MenuItem>
                <MenuItem
                  active={menuItem === MenuItems.FINANCE}
                  component={setView(
                    menuItem,
                    editingMe,
                    financeAttributes?.linkTo
                  )}
                  onClick={() =>
                    notSavedProfileCheck(editingMe, menuItem, dispatch)
                  }
                  icon={financeAttributes?.icon}
                >
                  {!collapsed && (
                    <div className="d-flex align-items-center quickfisco-menu-finance">
                      <div>{financeAttributes?.label}</div>
                      <div className="ms-2">
                        <Spinner animation="grow" variant="warning" size="sm" />
                      </div>
                    </div>
                  )}

                  {collapsed && <MenuCollapsedInfo label={'Finanziamenti'} />}
                </MenuItem>
                {user.stsEnabled && (
                  <>
                    {!collapsed && (
                      <p className="quickfisco-menu-section">
                        FUNZIONALITÀ PER MEDICI
                      </p>
                    )}
                    <SubMenu
                      label={
                        <div>
                          {!collapsed && 'Sistema STS'}
                          {collapsed && (
                            <MenuCollapsedInfo
                              label={'Sistema STS'}
                              subMenuItems={[
                                stsConfigurationAttributes,
                                stsHistoryAttributes,
                              ]}
                            />
                          )}
                        </div>
                      }
                      active={
                        menuItem === MenuItems.STS_CONFIGURATION ||
                        menuItem === MenuItems.STS_HISTORY
                      }
                      component={setView(
                        menuItem,
                        editingMe,
                        stsConfigurationAttributes?.linkTo
                      )}
                      open={
                        menuItem === MenuItems.STS_CONFIGURATION ||
                        menuItem === MenuItems.STS_HISTORY
                      }
                      onClick={() =>
                        notSavedProfileCheck(editingMe, menuItem, dispatch)
                      }
                      icon={stsConfigurationAttributes.icon}
                    >
                      {!collapsed && (
                        <div>
                          <MenuItem
                            active={menuItem === MenuItems.STS_CONFIGURATION}
                            component={setView(
                              menuItem,
                              editingMe,
                              stsConfigurationAttributes?.linkTo
                            )}
                            onClick={() =>
                              notSavedProfileCheck(
                                editingMe,
                                menuItem,
                                dispatch
                              )
                            }
                          >
                            {!collapsed && stsConfigurationAttributes?.label}
                          </MenuItem>
                          <MenuItem
                            active={menuItem === MenuItems.STS_HISTORY}
                            component={setView(
                              menuItem,
                              editingMe,
                              stsHistoryAttributes?.linkTo
                            )}
                            onClick={() =>
                              notSavedProfileCheck(
                                editingMe,
                                menuItem,
                                dispatch
                              )
                            }
                          >
                            {!collapsed && stsHistoryAttributes?.label}
                          </MenuItem>
                        </div>
                      )}
                    </SubMenu>
                  </>
                )}
                {!collapsed && (
                  <p className="quickfisco-menu-section">
                    COMUNICAZIONI{' '}
                    {(unreadCounters.allUnreadDocuments > 0 ||
                      unreadCounters.allUnreadMessages > 0) &&
                      '(' +
                        (unreadCounters.allUnreadDocuments +
                          unreadCounters.allUnreadMessages) +
                        ')'}
                  </p>
                )}
                <MenuItem
                  active={menuItem === MenuItems.DOCUMENTS}
                  component={setView(
                    menuItem,
                    editingMe,
                    documentsAttributes?.linkTo
                  )}
                  onClick={() =>
                    notSavedProfileCheck(editingMe, menuItem, dispatch)
                  }
                  icon={documentsAttributes?.icon}
                >
                  {!collapsed &&
                    `${documentsAttributes?.label} ${
                      unreadCounters.allUnreadDocuments > 0
                        ? `(${unreadCounters.allUnreadDocuments})`
                        : ''
                    }`}
                  {collapsed && (
                    <MenuCollapsedInfo
                      label={`Documenti ${
                        unreadCounters.allUnreadDocuments > 0
                          ? `(${unreadCounters.allUnreadDocuments})`
                          : ''
                      }`}
                    />
                  )}
                </MenuItem>
                <MenuItem
                  active={menuItem === MenuItems.MESSAGES}
                  component={setView(
                    menuItem,
                    editingMe,
                    messagesAttributes?.linkTo
                  )}
                  onClick={() =>
                    notSavedProfileCheck(editingMe, menuItem, dispatch)
                  }
                  icon={messagesAttributes?.icon}
                >
                  {!collapsed &&
                    `${messagesAttributes?.label} ${
                      unreadCounters.allUnreadMessages > 0
                        ? `(${unreadCounters.allUnreadMessages})`
                        : ''
                    }`}
                  {collapsed && (
                    <MenuCollapsedInfo
                      label={`Messaggi ${
                        unreadCounters.allUnreadMessages > 0
                          ? `(${unreadCounters.allUnreadMessages})`
                          : ''
                      }`}
                    />
                  )}
                </MenuItem>
              </>
            )}
          </Menu>
        </Sidebar>
        <div className="quickfisco-menu-collapse-container">
          <div
            className="quickfisco-menu-collapse"
            onClick={() => onClickCollapseSidebar()}
          >
            <QuickfiscoIcon
              name={
                collapsed
                  ? 'arrow-collapse-right.svg'
                  : 'arrow-collapse-left.svg'
              }
            />
          </div>
        </div>
      </div>
      <div className={'quickfisco-menu-footer'}>
        <Sidebar
          id="sidebar-menu-footer"
          collapsedWidth="120px"
          transitionDuration={300}
          rootStyles={{
            backgroundColor: 'white',
            border: 0,
            zIndex: 4,
            width: '280px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            paddingTop: '40px',
            paddingBottom: collapsed ? '40px' : '0px',
          }}
        >
          <Menu menuItemStyles={menuItemStyles}>
            {user.status === 4 && (
              <MenuItem
                component={setView(
                  menuItem,
                  editingMe,
                  bringAFrinedAttributes?.linkTo
                )}
                onClick={() => setBringAFriendModalIsOpen(true)}
                icon={bringAFrinedAttributes?.icon}
              >
                {!collapsed && bringAFrinedAttributes?.label}
                {collapsed && (
                  <MenuCollapsedInfo label={'Porta un forfettario!'} />
                )}
              </MenuItem>
            )}
            <MenuItem
              component={setView(menuItem, editingMe, logoutAttributes?.linkTo)}
              onClick={() => {
                if (editingMe && menuItem === MenuItems.PROFILE) {
                  dispatch(setProfileNotSavedModalIsOpen(true));
                } else {
                  dispatch(doLogout());
                }
              }}
              icon={logoutAttributes?.icon}
            >
              {!collapsed && logoutAttributes?.label}
              {collapsed && <MenuCollapsedInfo label={'Esci'} />}
            </MenuItem>
            {!collapsed && (
              <div className="quickfisco-menu-support">
                {user.status === 4 && (
                  <p className="mb-3">
                    Per supporto:{' '}
                    <a
                      target={'_blank'}
                      href={`mailto:${config.mail}`}
                      rel={'noreferrer'}
                    >
                      support@quickfisco.it
                    </a>
                  </p>
                )}
                <p>
                  © {new Date().getFullYear()} Quickfisco - Tutti i diritti
                  riservati
                </p>
              </div>
            )}
          </Menu>
        </Sidebar>
        <BringAFriend
          open={bringAFriendModalIsOpen}
          setOpen={setBringAFriendModalIsOpen}
        />
      </div>
    </div>
  );
}

function getAttributes(
  menuItem: MenuItems,
  compare: MenuItems,
  collapsed: boolean
): MenuAttributes | undefined {
  let attributes: MenuAttributes;

  switch (compare) {
    case MenuItems.HOME:
      attributes = {
        label: 'Dashboard',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.Home,
        icon:
          menuItem === MenuItems.HOME ? (
            <QuickfiscoIcon name={'home-positive.svg'} />
          ) : (
            <QuickfiscoIcon name={'home-negative.svg'} />
          ),
      };
      break;
    case MenuItems.CUSTOMER_INVOICE:
      attributes = {
        label: 'Fatture attive',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.CustomerInvoiceList,
        icon:
          menuItem === MenuItems.CUSTOMER_INVOICE ||
          menuItem === MenuItems.SUPPLIER_INVOICE ||
          menuItem === MenuItems.AUTO_INVOICE ||
          menuItem === MenuItems.PROFORMA_INVOICE ||
          menuItem === MenuItems.OCCASIONAL_JOB_INVOICE ||
          menuItem === MenuItems.CREDIT_SLIP ? (
            <QuickfiscoIcon name={'invoicing-positive.svg'} />
          ) : (
            <QuickfiscoIcon name={'invoicing-negative.svg'} />
          ),
      };
      break;
    case MenuItems.SUPPLIER_INVOICE:
      attributes = {
        label: 'Fatture passive',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.SupplierInvoiceList,
      };
      break;
    case MenuItems.PROFORMA_INVOICE:
      attributes = {
        label: 'Proforma',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.ProformaInvoice,
      };
      break;
    case MenuItems.OCCASIONAL_JOB_INVOICE:
      attributes = {
        label: 'Prestazioni Occasionali',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.OccasionalJobInvoice,
      };
      break;
    case MenuItems.CREDIT_SLIP:
      attributes = {
        label: 'Note di credito',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.CreditSlipInvoiceList,
      };
      break;
    case MenuItems.CONTACTS_CUSTOMER:
      attributes = {
        label: 'Clienti',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.CustomersList,
        icon:
          menuItem === MenuItems.CONTACTS_CUSTOMER ||
          menuItem === MenuItems.CONTACTS_SUPPLIER ? (
            <QuickfiscoIcon name={'contacts-positive.svg'} />
          ) : (
            <QuickfiscoIcon name={'contacts-negative.svg'} />
          ),
      };
      break;
    case MenuItems.CONTACTS_SUPPLIER:
      attributes = {
        label: 'Fornitori',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.SuppliersList,
      };
      break;
    case MenuItems.DOCUMENTS:
      attributes = {
        label: 'Documenti',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.Documents,
        icon:
          menuItem === MenuItems.DOCUMENTS ? (
            <QuickfiscoIcon name={'documents-positive.svg'} />
          ) : (
            <QuickfiscoIcon name={'documents-negative.svg'} />
          ),
      };
      break;
    case MenuItems.MESSAGES:
      attributes = {
        label: 'Messaggi',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.Messages,
        icon:
          menuItem === MenuItems.MESSAGES ? (
            <QuickfiscoIcon name={'messages-positive.svg'} />
          ) : (
            <QuickfiscoIcon name={'messages-negative.svg'} />
          ),
      };
      break;
    case MenuItems.STS_CONFIGURATION:
      attributes = {
        label: 'Configurazione',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.StsConfiguration,
        icon:
          menuItem === MenuItems.STS_CONFIGURATION ||
          menuItem === MenuItems.STS_HISTORY ? (
            <QuickfiscoIcon name={'sts-icon-positive.svg'} />
          ) : (
            <QuickfiscoIcon name={'sts-icon-negative.svg'} />
          ),
      };
      break;
    case MenuItems.STS_HISTORY:
      attributes = {
        label: 'Cronologia invii',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.StsHistory,
      };
      break;
    case MenuItems.AUTO_INVOICE:
      attributes = {
        label: 'Autofatture',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.AutoInvoice,
      };
      break;
    case MenuItems.TAX_CALCULATION:
      attributes = {
        label: 'Previsionale tasse',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.TaxCalculation,
        icon:
          menuItem === MenuItems.TAX_CALCULATION ? (
            <QuickfiscoIcon name={'forecast-positive.svg'} />
          ) : (
            <QuickfiscoIcon name={'forecast-negative.svg'} />
          ),
      };
      break;
    case MenuItems.FEES_REGISTER:
      attributes = {
        label: 'Corrispettivi',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.FeesRegister,
        icon:
          menuItem === MenuItems.FEES_REGISTER ? (
            <QuickfiscoIcon name={'fees-register-positive.svg'} />
          ) : (
            <QuickfiscoIcon name={'fees-register-negative.svg'} />
          ),
      };
      break;
    case MenuItems.BRINGAFRIENDMODAL:
      attributes = {
        label: 'Porta un forfettario!',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: '#',
        icon: <QuickfiscoIcon name={'bring-a-friend-sidebar.svg'} />,
      };
      break;
    case MenuItems.LOGOUT:
      attributes = {
        label: 'Esci',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.Login,
        icon: <QuickfiscoIcon name={'logout.svg'} />,
      };
      break;
    case MenuItems.FINANCE:
      attributes = {
        label: 'Finanziamenti',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.Finance,
        icon:
          menuItem === MenuItems.FINANCE ? (
            <QuickfiscoIcon name={'finanziamenti-neg.svg'} />
          ) : (
            <QuickfiscoIcon name={'finanziamenti-pos.svg'} />
          ),
      };
      break;
    case MenuItems.TAX_RETURN:
      attributes = {
        label: 'Dichiarazione redditi',
        className: 'qm-inactive-container',
        textClassName: '',
        linkTo: uri.TaxReturn,
        icon:
          menuItem === MenuItems.TAX_RETURN ? (
            <QuickfiscoIcon name={'tax-return-negative.svg'} />
          ) : (
            <QuickfiscoIcon name={'tax-return-positive.svg'} />
          ),
      };
      break;
    default:
      return undefined;
  }

  if (menuItem === compare) {
    attributes.className = 'qm-active-container';
    attributes.textClassName = 'qm-active-text';
  }

  return attributes;
}

function setView(
  menuItem: MenuItems,
  editingMe: boolean,
  linkTo?: string
): React.ReactElement | undefined {
  if (!linkTo) {
    return undefined;
  }
  if (editingMe && menuItem === MenuItems.PROFILE) {
    return undefined;
  } else {
    return <Link to={linkTo} />;
  }
}

function notSavedProfileCheck(
  editingMe: boolean,
  menuItem: MenuItems,
  dispatch: Function
) {
  if (editingMe && menuItem === MenuItems.PROFILE) {
    dispatch(setProfileNotSavedModalIsOpen(true));
  }
}

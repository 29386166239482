import { format, isAfter } from "date-fns";
import { it } from 'date-fns/locale';
import { useState } from "react";
import config from '../../../config.json';
import useComponentVisible from "../../hooks/componentVisible";
import { FeesRegisterModel } from "../../models/feesRegisterModel";
import { formatNumberIT } from "../../utils/number";
import { FeesRegisterMenu } from "../feesRegisterMenu/feesRegisterMenu";
import { QuickfiscoIcon } from "../quickfiscoIcon/quickfiscoIcon";
import { FeesRegisterEditOrSave } from "../feesRegisterEdit/feesRegisterEdit";
import { useAppDispatch } from "../../redux/hooks";
import { feesRegisterFindById } from "../../redux/slices/feesRegisterSlice";
import './feesRegisterTableRow.css';
import lang from './feesRegisterTableRow.json';

interface Props {
    feesRegister: FeesRegisterModel
}

export function FeesRegisterTableRow(props: Props) {
    const { feesRegister } = props;

    const [onMouseOver, setOnMouseOver] = useState(false);

    const showMenu = useComponentVisible(false);
    const showPopup = useComponentVisible(false);

    const dispatch = useAppDispatch();

    const [openModal, setOpenModal] = useState(false);

    const showModal = () => {
        if (!isAfter(feesRegister.date, new Date())) {
            if (feesRegister.id !== undefined) {
              dispatch(feesRegisterFindById(feesRegister.id));
            }
            setOpenModal(true);
            showMenu.setIsVisible(false);
          }
    }

    return (
        <div className="row fees-register-table-row fees-register-table-row-border-bottom">
            <div className="col-12">
                <div className="row" onClick={() => {showModal() }}>
                    <div className="col-2 fees-register-table-cells fees-register-table-row-border-right">
                        <span className="fees-register-table-row-date">
                            {
                                format(new Date(feesRegister.date), 'dd MMMM', {
                                    locale: it
                                })
                            }
                        </span>
                    </div>
                    <div className="col-2 fees-register-table-cells fees-register-table-row-border-right">
                        <span className="fees-register-table-row-amount">
                            {!feesRegister.amount ? '-' : config.EUR + formatNumberIT(feesRegister.amount)}
                        </span>
                    </div>
                    <div
                        className="col-3 position-relative fees-register-table-row-border-right"
                        style={{ padding: "15px 20px" }}
                        onMouseOver={() => showPopup.setIsVisible(true)}
                        onMouseOut={() => showPopup.setIsVisible(false)}
                    >
                        <div className="list-crop">
                            <span className="fees-register-table-row-vat">
                                {lang.ivaBold}
                                <span className="fw-normal">
                                    {lang.iva}
                                </span>
                            </span>
                        </div>
                        <div>
                            <div
                                className={'fees-register-table-row-popup'}
                                ref={showPopup.ref}
                            >
                                {
                                    showPopup.isVisible && (
                                        <div className={'fees-register-table-row-popup-container'}>
                                            <span className="fees-register-table-row-vat">
                                                {lang.ivaBold}
                                                <span className="fw-normal fees-register-table-row-vat-gray">
                                                    {lang.iva}
                                                </span>
                                            </span>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col fees-register-table-row-border-right">
                        <div className="row">
                            <div className="col-4 fees-register-table-cells fees-register-table-row-border-right">
                                <span className="fees-register-table-row-amount">
                                    {!feesRegister.amount_IT ? '-' : config.EUR + formatNumberIT(feesRegister.amount_IT)}
                                </span>
                            </div>
                            <div className="col-4 fees-register-table-cells fees-register-table-row-border-right">
                                <span className="fees-register-table-row-amount">
                                    {!feesRegister.amount_EU ? '-' : config.EUR + formatNumberIT(feesRegister.amount_EU)}
                                </span>
                            </div>
                            <div className="col-4 fees-register-table-cells">
                                <span className="fees-register-table-row-amount">
                                    {!feesRegister.amount_EXTRA_EU ? '-' : config.EUR + formatNumberIT(feesRegister.amount_EXTRA_EU)}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        className={'col-auto fees-register-table-cells position-relative'} onClick={(e) => { e.stopPropagation() }}>
                        {
                            showMenu.isVisible ? (
                                <div style={{ width: '45px' }} className='d-flex align-items-center justify-content-center'>
                                    <QuickfiscoIcon name={'menu-active.svg'} />
                                </div>
                            ) : (
                                <div
                                    className='d-flex align-items-center justify-content-center'
                                    style={{ width: '45px' }}
                                    onMouseOver={() => setOnMouseOver(false)}
                                    onMouseOut={() => setOnMouseOver(false)}
                                    onClick={() => showMenu.setIsVisible(true)}
                                >
                                    <QuickfiscoIcon name={onMouseOver ? 'menu-hover.svg' : 'menu.svg'} />
                                </div>
                            )
                        }
                        <div
                            className={'fees-register-table-row-menu'}
                            ref={showMenu.ref}
                        >
                            <FeesRegisterMenu
                                feesRegister={feesRegister}
                                componentVisible={showMenu}
                                innerRef={showMenu.ref}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <FeesRegisterEditOrSave
                feesRegister={feesRegister}
                open={openModal}
                setOpen={() => { setOpenModal(false); showMenu.setIsVisible(false) }}
            />
        </div>
    )
}

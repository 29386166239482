import React, { useEffect, useState } from 'react';
import { ContactModel } from '../../models/contactModel';
import { InvoiceStatusType, ReimbursementOfExpenseModel } from '../../models/invoiceModel';
import { useAppSelector } from '../../redux/hooks';
import { CustomerInvoiceService } from '../../services/customerInvoiceService';
import { UserService } from '../../services/userService';
import { downloadPdf } from '../../utils/file';
import { QuickfiscoIcon } from '../quickfiscoIcon/quickfiscoIcon';
import { QuickfiscoInput } from '../quickfiscoInput/quickfiscoInput';
import {
  ReimbursementOfExpensesInput
} from '../reimbursementOfExpensesInput/reimbursementOfExpensesInput';
import './reimbursementsOfExpense.css';
import { formatNumberIT } from '../../utils/number';
import lang from './reimbursementsOfExpense.json';

interface Props {
  error: boolean;
  customer?: ContactModel;
  reimbursementsOfExpenses: ReimbursementOfExpenseModel[];
  addReimbursementOfExpense: (value: ReimbursementOfExpenseModel) => boolean;
  deleteReimbursementOfExpense: (index: number) => void;
}

export function ReimbursementsOfExpense(props: Props) {
  const { error } = props;
  const { customer } = props;
  const { reimbursementsOfExpenses } = props;
  const { addReimbursementOfExpense } = props;
  const { deleteReimbursementOfExpense } = props;

  const [openReimbursementOfExpenseInput, setOpenReimbursementOfExpenseInput] = useState(false);

  const userState = useAppSelector(state => state.user);
  const customerInvoiceState = useAppSelector(state => state.customerInvoice);
  const customerInvoiceSaveOrDuplicateOrEditState = useAppSelector(state => state.customerInvoiceSaveOrDuplicateOrEdit);

  const operation = customerInvoiceSaveOrDuplicateOrEditState.operation

  const user = userState.user
  const invoice = customerInvoiceState.invoice

  const [userIsNotEnabled, setUserIsNotEnabled] = useState(UserService.isNotEnabled(user))
  const [invoiceIsEditable, setInvoiceIsEditable] = useState(CustomerInvoiceService.isEditable(invoice))

  useEffect(() => {
    setUserIsNotEnabled(UserService.isNotEnabled(user))
  }, [user.enabled, user.status])

  useEffect(() => {
    if ((operation === 'edit' || operation === 'duplicate') &&
      invoice.status !== InvoiceStatusType.ALREADY_TRANSMITTED) {
    setInvoiceIsEditable(CustomerInvoiceService.isEditable(invoice))}
  }, [invoice.status])

  const items = getItems(userIsNotEnabled || !invoiceIsEditable, reimbursementsOfExpenses, deleteReimbursementOfExpense);

  return (
    <div className={'reimbursement-of-expense-container'}>
      <div className={'row'}>
        <div className={'col'}>
          <span className={'reimbursement-of-expense-title'}>
            {lang.title}
          </span>
        </div>
        <div className={'col-auto d-flex justify-content-end'}>
          <QuickfiscoIcon
            name={'new.svg'}
            className={!userIsNotEnabled && invoiceIsEditable && !!customer ? 'w-f-35' : 'w-f-35 reimbursement-of-expense-add-new-disabled'}
            isButton={!userIsNotEnabled && invoiceIsEditable && !!customer}
            onClick={() => { (!userIsNotEnabled && invoiceIsEditable && !!customer) && setOpenReimbursementOfExpenseInput(true) }}
          />
        </div>
      </div>
      {
        reimbursementsOfExpenses.length === 0 ? (
          <div>
            <span className={'reimbursement-of-expense-subtitle'}>
              {lang.placeholder}
            </span>
          </div>
        ) : (
          <div>
            {items}
          </div>
        )
      }
      <ReimbursementOfExpensesInput
        open={openReimbursementOfExpenseInput}
        setOpen={(open: boolean) => setOpenReimbursementOfExpenseInput(open)}
        error={error}
        addReimbursementOfExpense={addReimbursementOfExpense}
      />
    </div>
  );
}

function getItems(
  disabled: boolean,
  reimbursementsOfExpenses: ReimbursementOfExpenseModel[],
  deleteReimbursementOfExpense: (index: number) => void
): React.ReactNode[] {
  return reimbursementsOfExpenses.map((_, index) =>
    getItem(index, disabled, reimbursementsOfExpenses, deleteReimbursementOfExpense)
  );
}

function getItem(
  index: number,
  disabled: boolean,
  reimbursementsOfExpenses: ReimbursementOfExpenseModel[],
  deleteReimbursementOfExpense: (index: number) => void
): React.ReactNode {
  return (
    <div
      className={'row mt-1 d-flex align-items-center'}
      key={'item-' + reimbursementsOfExpenses[index].key}
    >
      <div className={'col-7'}>
        <QuickfiscoInput
          id='reimbursements-of-expense-file-input-description'
          type={'text'}
          styleType={'default'}
          disabled={true}
          label={lang.labelDescription}
          value={reimbursementsOfExpenses[index].description}
        />
      </div>
      <div className={'col-3'}>
        <QuickfiscoInput
          id='reimbursements-of-expense-file-input-amount'
          type={'text'}
          styleType={'default'}
          disabled={true}
          label={lang.labelAmount}
          value={`${formatNumberIT(reimbursementsOfExpenses[index].amount)}€`}
        />
      </div>
      <div className={'col-1'}>
        <QuickfiscoIcon
          name={'delete-icon.svg'}
          isButton={!disabled}
          onClick={() => {
            !disabled && deleteReimbursementOfExpense(index);
          }}
        />
      </div>
      { reimbursementsOfExpenses[index].file && 
        <div className={'col-1 mb-2'}>
          <QuickfiscoIcon
            name={'download.svg'}
            className={'quickfisco-input-download-reimbursements-of-expenses'}
            isButton={true}
            onClick={() => {
              reimbursementsOfExpenses[index].file?.arrayBuffer().then((data) => getDownloadReimbursementsOfExpensesName(reimbursementsOfExpenses[index].file?.name, data))
            }}
          />
        </div>
      }
    </div>
  );
}

function getDownloadReimbursementsOfExpensesName(
  name?: string,
  data?: ArrayBuffer,
): void {
  if (!name || !data) {
    return
  }

  downloadPdf(name, data)
}
import { useNavigate } from 'react-router-dom';
import { QuickfiscoButton } from '../quickfiscoButton/quickfiscoButton';
import uri from '../../routers/quickfiscoUri.json';
import './taxDeclaration.css';

export function TaxDeclaration() {
  const navigate = useNavigate();

  return (
    <div className="tax-declaration-container box-shadow p-4">
      <div className={'row d-flex align-items-center h-100'}>
        <div className={'col-12'}>
          <div className="row">
            <div className="col-12">
              <span className={'tax-declaration-title-yellow'}>
               DICHIARAZIONE DEI REDDITI {new Date().getFullYear()}
              </span>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
              <span className={'tax-declaration-title-white'}>
                Questionario disponibile da Aprile
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-10 offset-1 mt-3">
              <QuickfiscoButton
                id={'tax-declaration-procedure'}
                label={'COMPILA DA APRILE'} //TODO APRILE
                type={'disabled'}
                bold={true}
                onClick={() => navigate(uri.TaxReturn)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
